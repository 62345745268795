import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-settings-social-disconnected',
  templateUrl: './settings-social-disconnected.component.html',
  styleUrls: ['./settings-social-disconnected.component.css']
})
export class SettingsSocialDisconnectedComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
