<section class="banner position-relative pt-4">
    <div class="container">
        <div class="logo position-relative">
            <img src="assets/images/logo.svg" alt="">
        </div>
        <div class="row align-items-center">
            <div class="col">
                <div class="banner_text">
                    <h1 class="display-5"><span class="text_color_pink">Lorem ipsum</span> dolor sit amet</h1>
                    <h4 class="fst-italic text_color_darkgrey">Lorem ipsum dolor sit amet</h4>
                    <p class="text_color_lightgrey fs18">consectetur adipiscing elit. Mauris ullamcorper aliquam
                        risus et condimentum. Duis at imperdiet enim.</p>
                </div>
                <div class="mt-4 banner_btns position-relative">
                    <button [routerLink]="['/selectRole']" class="btn btn-primary">Login</button>
                    <button [routerLink]="['/signup']" class="btn btn-info ms-4">Signup</button>
                </div>
            </div>
            <div class="col">
                <div class="banner_phone_img">
                    <img src="assets/images/banner_phone.png" alt="">
                </div>
            </div>
        </div>
    </div>
</section>
