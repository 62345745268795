import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiListService } from 'src/app/services/Api/api-list.service';
import { MessageHandlingService } from 'src/app/services/Common/message-handling.service';
import { HttpFunctionListService } from 'src/app/services/Http/http-function-list.service';
import { Common_FunctionService } from '../../Pages/CommonInfluncer/common-function.service';
declare var $: any;
declare var otpVerification: any;
// import { Common_FunctionService } from '../CommonInfluncer/common-function.service';
// import { Common_FunctionService } from '../../../services/';



@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  userType: string;
  url: any;
  forgotForm: UntypedFormGroup;
  forgotUrl: string;
  resendUrl:string
  otpUrl:string
  otpform: UntypedFormGroup;

  countryCode: any;
  initialConfig: any = { initialCountry: 'ae', preferredCountries: ['ae', 'in'] }
  countryCode1: any
  mobilevalue:any
  loginForm: UntypedFormGroup;
  data: any;
  posts: any;
  user: any;
  profileData: any;
  personaldata: any;
  socialdata: any;
  resendMobile:any
  timeCount: any = 30;
  timeCountInSeconds: any;
  completetimeCountInSeconds: any = ' in ' + 30 + ' sec';
 allValues :boolean
  constructor(public messgae: MessageHandlingService, public router: Router, public activatedRoute: ActivatedRoute, public apiList: ApiListService, public http: HttpFunctionListService, public formBuilder: UntypedFormBuilder) {
    //  //debugger
    this.activatedRoute.paramMap.subscribe(params => {
      this.userType = params.get('id') //+ string to number
      console.log(this.userType)
      // this.countryCode1 = '971'
    })
  }

  ngOnInit() {
    new otpVerification;
    // //debugger
    $("#what_we_do_video").on('hidden.bs.modal', function (e) {
      $("#what_we_do_video iframe").attr("src", $("#what_we_do_video iframe").attr("src"));
    });
    this.forgotForm = this.formBuilder.group({
      mobileCountryCode: ['', [Validators.required]],
      mobile: ['', [Validators.required]],
      email: ['', Validators.required]
    });
    // //debugger
    this.loginForm = this.formBuilder.group({
      mobileCountryCode: [''],
      // mobile: ['', [Validators.required]],
      email: ['', Validators.required],
      password: ['', Validators.required],
      check: ['Email'],
    });
    // //debugger

    /*otp*/
    this.otpform = this.formBuilder.group({
      one: ['', [Validators.required]],
      two: ['', [Validators.required]],
      three: ['', [Validators.required]],
      four: ['', [Validators.required]]
    });

    console.log(this.otpform)
    // //debugger


  }

  onCountryChange(data) {
    console.log(data)
    this.countryCode = data.dialCode
  }

  hasError(event) {
    console.log('helloooo')
    console.log(event)
  }

  loginSubmit() {
    this.url = this.userType == 'Client' ? this.apiList.clientLogin : this.userType == 'Influencer' ? this.apiList.influncerLogin : this.userType == 'Talent' ? this.apiList.talentLogin : ''
    this.mobilevalue = this.loginForm.value.check == 'Phone' ? this.loginForm.value.email : ''   // one field is used for both field email and password
    const emailvalue = this.loginForm.value.check == 'Email' ? this.loginForm.value.email : ''
    console.log(localStorage.getItem('fcmToken'))
    let data = {
      email: emailvalue,
      mobile: this.mobilevalue,
      mobileCountryCode: '+' + this.countryCode1,
      password: this.loginForm.value.password,
      fcmToken: localStorage.getItem('fcmToken')
    }
    this.http.post(this.url, data).subscribe((res: any) => {
      console.log(res)
      if (res.statusCode == 200) {
        localStorage.setItem('token', res.data.userToken)
        this.allValues = false


        if (this.userType == 'Influencer') {
          if (res.data.user.isMobileVerified == false) {
            this.countDownTimer();
            $('#otp').modal('show');
          }
          else {
            this.http.get(this.apiList.screenstatus).subscribe((res: any) => {
              console.log(res)
              if (res.data.status.nextScreen == 'signup') {
                this.router.navigate['/signup-register']
              }
              else if (res.data.status.nextScreen == 'verifyOtp') {
                $('#otp').modal('show');              }
      
              else if (res.data.status.nextScreen == 'createAccount') {
                this.router.navigate(['/create-account'])
      
              }
      
              else if (res.data.status.nextScreen == 'addAddress') {
                this.router.navigate(['/create-account'])
      
              }
              else if (res.data.status.nextScreen == 'bankDetail') {
                this.router.navigate(['/create-account'])
      
              }
              else if (res.data.status.nextScreen == 'personalDetail') {
                this.router.navigate(['/create-account'])
              }
              else if(res.data.status.nextScreen=='identityVerification'){
                this.router.navigate(['/identity-verification'])
      
              }
              else if(res.data.status.nextScreen=='socialMedia'){
                this.router.navigate(['/connect'])
      
              }
              else if (res.data.status.nextScreen == 'home') {
                this.router.navigate(['/campaign'])
              }
            })
          }
        }
        else if (this.userType == 'Talent') {
          if (res.data.user.isMobileVerified == false) {
            this.countDownTimer();
            $('#otp').modal('show');
          }
          else {
  
            this.http.get(this.apiList.skipTalentScreenStatus).subscribe((res: any) => {
              console.log(res)
              if (res.data.status.nextScreen == 'signup') {
                this.router.navigate['/register-talent']

              }

              else if (res.data.status.nextScreen == 'verifyOtp') {
                $('#otp').modal('show');

              }

              else if (res.data.status.nextScreen == 'createAccount') {
                this.router.navigate(['/create-account-talent'])

              }

              else if (res.data.status.nextScreen == 'addAddress') {
                this.router.navigate(['/create-account-talent'])

              }
              else if (res.data.status.nextScreen == 'bankDetail') {
                this.router.navigate(['/create-account-talent'])

              }
              else if (res.data.status.nextScreen == 'personalDetail') {
                this.router.navigate(['/create-account-talent'])

              }
              else if (res.data.status.nextScreen == 'personalDoc') {
                this.router.navigate(['/register-detail-talent'])

              }
             
              else if (res.data.status.nextScreen == 'home') {
                this.router.navigate(['/talent'])

              }

            })
          }

          // this.router.navigate(['/talent'])
        }

        else if (this.userType == 'Client') {
          if (res.data.user.isMobileVerified == false) {
            this.countDownTimer();
            $('#otp').modal('show');
          }
          else {
      
            this.http.get(this.apiList.screenstatusBusiness).subscribe((res: any) => {
              console.log(res)
              // if (res.data.status.nextScreen == 'signup') {
              //   this.router.navigate['/client-signup']
      
              // }
      
              // else if (res.data.status == 'verifyOtp') {
              //   $('#otp').modal('show');
      
              // }
      
               if (res.data.status.isSocialAccountScreenCompleted == false) {
                this.router.navigate(['/client-social-media-url'])
      
              }
             
            
             
              else if (res.data.status.isSocialAccountScreenCompleted == true) {
                this.router.navigate(['/client'])
      
              }
      
            })
          }
      
          // this.router.navigate(['/talent'])
        }

        // else if (this.userType == 'Client') {

        //   if (res.data.user.isMobileVerified == false) {

        //     $('#otp').modal('show');
        //   }
        //   else {
        //     this.router.navigate(['/client'])
        //   }

        //   this.messgae.success(res.message)


         
        // }
      }
    }, error => {
    })
  }

  countDownTimer() {
    var timeDiff = (+ new Date() + 30000) - (+new Date())
    this.timeCountInSeconds = Math.floor((timeDiff) % (1000 * 60) / 1000)

    var x = setInterval(() => {
      this.timeCountInSeconds -= 1
      this.completetimeCountInSeconds = ' in ' + this.timeCountInSeconds + ' sec'
      console.log(this.completetimeCountInSeconds)

      if (this.timeCountInSeconds <= 0) {
        clearInterval(x)
        this.completetimeCountInSeconds = 'now'
        //  this.resendOtpTalent();


      }
    }, 1000)
  }

  onSubmitOtp() {
    console.log('hello')
    console.log(this.userType)
  //  if (this.userType == 'Talent') {
      this.otpSend();
  //  }
  }

 

  onCountryChange1(data) {
    console.log(data)
    this.countryCode1 = data.dialCode
  }

  reset(value) {   //reset the form value when we change the login via
    this.loginForm.reset()
    this.loginForm.patchValue({
      check: value
    })
  }




  verifynumber() {

    if (this.userType == 'Influencer') {
      this.router.navigate(['/campaign'])
    }

    else if (this.userType == 'Talent') {
      this.router.navigate(['/talent'])
    }

    else if (this.userType == 'Client') {
      this.router.navigate(['/client'])
    }

  }


  resendOtp() {
    console.log('hi')
      if (this.timeCountInSeconds <= 0) {
        this.allresendOTP();
      }
  }



  // resendOtpTalent() {
  //   console.log('login Otp Submit')

  //   const mobilevalue = this.loginForm.value.check == 'Phone' ? this.loginForm.value.email : ''   // one field is used for both field email and password

  //   let data = {
  //     mobile: mobilevalue,
  //     mobileCountryCode: '+' + this.countryCode1,
  //   }

  //   this.http.post(this.apiList.resendOtpTalent, data).subscribe((res: any) => {
  //     console.log(res)
  //     if (res.statusCode == 200) {
  //       this.messgae.success(res.message)
  //       this.countDownTimer();
  //     } else {
  //     }
  //   }, error => {
  //     this.messgae.error(error.error.message)

  //   })
  // }


 


    // for  otp influencr

      //phone
 
  // Resend  otp for all

  allresendOTP() {

      // const mobilevalue = this.loginForm.value.check == 'Phone' ? this.loginForm.value.email : ''
      this.resendUrl = this.userType == 'Client' ? this.apiList.resendOtpClient : this.userType == 'Influencer' ? this.apiList.resendOtpInfluencer : this.userType == 'Talent' ? this.apiList.resendOtpTalent : ''
      // let data = {
      //   mobile: mobilevalue,
      //   mobileCountryCode:'+' + this.countryCode,
      // }

      if (this.allValues == true) {
        let data = {
          mobileCountryCode: '+' + this.countryCode,
          mobile: this.resendMobile,
          // email: this.forgotForm.value.email
        }
        console.log(data)
        this.http.post(this.resendUrl, data).subscribe((res: any) => {
          if (res.statusCode == 200) {
            // this.countDownTimer();
            // $('#forgotPass').modal('hide');
            // $('#otp').modal('show');
  
            this.messgae.success(res.message)
  
  
          }
        }, error => {
          console.log(error.error.message);
  
        })
      }
      else{

        let data = {
          mobile: this.mobilevalue,
          mobileCountryCode: '+' + this.countryCode1,
        }
        console.log(data)
        this.http.post(this.resendUrl, data).subscribe((res: any) => {
          if (res.statusCode == 200) {
            // this.countDownTimer();
            // $('#forgotPass').modal('hide');
            // $('#otp').modal('show');
  
            this.messgae.success(res.message)
  
   
          }
        }, error => {
          console.log(error.error.message);
  
        })


          
      }
     
  

  }

  //  forget  password for all
  forgotSubmit() {
    this.resendMobile = this.forgotForm.value.mobile
    
    if (this.forgotForm.value.mobile || this.forgotForm.value.email) {
      this.forgotUrl = this.userType == 'Client' ? this.apiList.clientForgotPass : this.userType == 'Influencer' ? this.apiList.influncerForgotPass : this.userType == 'Talent' ? this.apiList.talentForgotPass : ''
      let data = {
        mobileCountryCode: '+' + this.countryCode,
        mobile: this.forgotForm.value.mobile,
        email: this.forgotForm.value.email
      }
      console.log(data)
      this.http.post(this.forgotUrl, data).subscribe((res: any) => {
        if (res.statusCode == 200) {
          $('#forgotPass').modal('hide');
          $('#otp').modal('show');
          this.countDownTimer()
          this.forgotForm.reset()
          this.allValues = true

          this.messgae.success(res.message)


        }
      }, error => {
        console.log(error.error.message);

      })
    } else {
      this.messgae.error('Enter the Email Or Mobile Number')
    }
  }

   //  otp  send for all

   otpSend() {
    
      this.otpUrl = this.userType == 'Client' ? this.apiList.clientotp : this.userType == 'Influencer' ? this.apiList.influncerotp : this.userType == 'Talent' ? this.apiList.talentotp : ''
      let otpvalue = this.otpform.value.one + this.otpform.value.two + this.otpform.value.three + this.otpform.value.four;
      const mobilevalue = this.loginForm.value.check == 'Phone' ? this.loginForm.value.email : ''   // one field is used for both field email and password
  
      let data = {
        mobile: mobilevalue,
        mobileCountryCode: '+' + this.countryCode1,
        otp: otpvalue
      }
      this.http.post(this.otpUrl, data).subscribe((res: any) => {
        console.log(res)
        if (res.statusCode == 200) {
          localStorage.setItem('token', res.data.token)
          $('#otp').modal('hide');
          $('#otp_success').modal('show');
          this.messgae.success(res.message)
          this.otpform.reset()
  
        } else {
          //alert(res.message)
        }
      }, error => {
        //alert(error.error.message)
  
      })
   
  }

  



  // O. code 

  // loginSubmit() {
  //   this.url = this.userType == 'Client' ? this.apiList.clientLogin : this.userType == 'Influencer' ? this.apiList.influncerLogin : this.userType == 'Talent' ? this.apiList.talentLogin : ''
  //   const mobilevalue = this.loginForm.value.check == 'Phone' ? this.loginForm.value.email : ''   // one field is used for both field email and password
  //   const emailvalue = this.loginForm.value.check == 'Email' ? this.loginForm.value.email : ''
  //   console.log(localStorage.getItem('fcmToken'))
  //   let data = {
  //     email: emailvalue,
  //     mobile: mobilevalue,
  //     mobileCountryCode: '+' + this.countryCode1,
  //     password: this.loginForm.value.password,
  //     fcmToken: localStorage.getItem('fcmToken')
  //   }
  //   this.http.post(this.url, data).subscribe((res: any) => {
  //     console.log(res)
  //     if (res.statusCode == 200) {
  //       // //debugger
  //       // this.common.subject.subscribe((res: any) => {
  //       //   this.profileData = res ;
  //       //   this.getpersonalData()
  //       // }) ; 
  //       localStorage.setItem('token', res.data.userToken)
  //       if (this.userType == 'Influencer') {
  //         // this.common.subject.subscribe((res: any) => {
  //         //   this.profileData = res ;
  //         this.getpersonalData();
  //         this.getInfluencerInfo();
  //         this.getSocialAccounts()
  //         // }) ; 
  //         console.log(this.profileData)

  //         // this.http.get(this.apiList.skipTalentScreenStatus).subscribe((res:any)=>{
  //         //   console.log(res)
  //         // })

  //         // if( this.profileData == null || this.profileData != null ){this.router.navigate(['/create-account'])}
  //         // else if (this.personaldata != null && this.socialdata == null ) { this.router.navigate(['/connect']) }
  //         // else { this.router.navigate(['/campaign']) }
  //         //  if(this.profileData.bio == null){
  //         //     this.router.navigate(['/create-account'])
  //         //     }
  //         //     else if (this.profileData.bio != null){
  //         //       this.router.navigate(['/create-account'])
  //         //     }

  //         //     else if(this.personaldata != null){
  //         //       this.router.navigate(['/connect'])
  //         //     }



  //         if (res.data.user.isMobileVerified == false) {
  //           $('#otp').modal('show');
  //         }
  //         else {
  //           this.router.navigate(['/campaign'])
  //         }


  //         //email
  //         // if(res.data.user.isEmailVerified == false){
  //         //   // $('#otpemail').modal('show');
  //         // }
  //         // else{
  //         //   this.router.navigate(['/campaign'])
  //         // }

  //       }

  //       else if (this.userType == 'Talent') {
  //         // this.http.get(this.apiList.skipTalentScreenStatus).subscribe((res:any)=>{
  //         //   console.log(res)
  //         //   console.log(res.data.status)
  //         //   console.log(res.data.status.nextScreen)
  //         //   if(res.data.status.nextScreen=='signup' || res.data.status.nextScreen=='verifyOtp' || res.data.status.nextScreen=='createAccount' || res.data.status.nextScreen=='addAddress'
  //         //   || res.data.status.nextScreen=='bankDetail' || res.data.status.nextScreen=='personalDetail' || res.data.status.nextScreen=='personalDoc' || res.data.status.nextScreen=='identityVerification'
  //         //   || res.data.status.nextScreen=='socialMedia' ||  res.data.status.nextScreen=='home'
  //         //   ){
  //         //     console.log('Hello')
  //         //     this.router.navigate(['/create-account-talent'])

  //         //   }

  //         //   else{
  //         //     console.log('not h')
  //         //   }

  //         //   // else if(res.data.status.nextScreen=='verifyOtp'){
  //         //   //   this.router.navigate([''])

  //         //   // }

  //         //   // else if(res.data.status.nextScreen=='createAccount'){
  //         //   //   this.router.navigate(['/create-account-talent'])

  //         //   // }

  //         //   // else if(res.data.status.nextScreen=='addAddress'){
  //         //   //   this.router.navigate([''])

  //         //   // }
  //         //   // else if(res.data.status.nextScreen=='bankDetail'){
  //         //   //   this.router.navigate([''])

  //         //   // }
  //         //   // else if(res.data.status.nextScreen=='personalDetail'){
  //         //   //   this.router.navigate([''])

  //         //   // }
  //         //   // else if(res.data.status.nextScreen=='personalDoc'){
  //         //   //   this.router.navigate([''])

  //         //   // }
  //         //   // else if(res.data.status.nextScreen=='identityVerification'){
  //         //   //   this.router.navigate([''])

  //         //   // }
  //         //   // else if(res.data.status.nextScreen=='socialMedia'){
  //         //   //   this.router.navigate([''])

  //         //   // }
  //         //   // else if(res.data.status.nextScreen=='home'){
  //         //   //   this.router.navigate([''])

  //         //   // }

  //         // })

  //         if (res.data.user.isMobileVerified == false) {
  //           this.countDownTimer();
  //           $('#otp').modal('show');
  //         }
  //         else {
  //           // this.router.navigate(['/talent'])
  //           // this.http.get(this.apiList.skipTalentScreenStatus).subscribe((res:any)=>{
  //           //       console.log(res)
  //           //       console.log(res.data.status)
  //           //       console.log(res.data.status.nextScreen)
  //           //       // if(res.data.status.nextScreen=='signup' || res.data.status.nextScreen=='verifyOtp' || res.data.status.nextScreen=='createAccount' || res.data.status.nextScreen=='addAddress'
  //           //       // || res.data.status.nextScreen=='bankDetail' || res.data.status.nextScreen=='personalDetail' || res.data.status.nextScreen=='personalDoc' || res.data.status.nextScreen=='identityVerification'
  //           //       // || res.data.status.nextScreen=='socialMedia' ||  res.data.status.nextScreen=='home'
  //           //       // ){
  //           //       //   console.log('Hello')
  //           //       //   this.router.navigate(['/create-account-talent'])

  //           //       // }

  //           //       // else{
  //           //       //   console.log('not h')
  //           //       // }
  //           this.http.get(this.apiList.skipTalentScreenStatus).subscribe((res: any) => {
  //             console.log(res)
  //             if (res.data.status.nextScreen == 'signup') {
  //               this.router.navigate['/register-talent']

  //             }

  //             else if (res.data.status.nextScreen == 'verifyOtp') {
  //               $('#otp').modal('show');

  //             }

  //             else if (res.data.status.nextScreen == 'createAccount') {
  //               this.router.navigate(['/create-account-talent'])

  //             }

  //             else if (res.data.status.nextScreen == 'addAddress') {
  //               this.router.navigate(['/create-account-talent'])

  //             }
  //             else if (res.data.status.nextScreen == 'bankDetail') {
  //               this.router.navigate(['/create-account-talent'])

  //             }countryCode1
  //             else if (res.data.status.nextScreen == 'personalDetail') {
  //               this.router.navigate(['/create-account-talent'])

  //             }
  //             else if (res.data.status.nextScreen == 'personalDoc') {
  //               this.router.navigate(['/register-detail-talent'])

  //             }
  //             // else if(res.data.status.nextScreen=='identityVerification'){
  //             //   this.router.navigate([''])

  //             // }
  //             // else if(res.data.status.nextScreen=='socialMedia'){
  //             //   this.router.navigate([''])

  //             // }
  //             else if (res.data.status.nextScreen == 'home') {
  //               this.router.navigate(['/talent'])

  //             }

  //           })
  //         }

  //         // this.router.navigate(['/talent'])
  //       }

  //       else if (this.userType == 'Client') {

  //         if (res.data.user.isMobileVerified == false) {

  //           $('#otp').modal('show');
  //         }
  //         else {
  //           this.router.navigate(['/client'])
  //         }

  //         this.messgae.success(res.message)


  //         // if(res.data.user.isEmailVerified == false){
  //         //   // $('#otpemail').modal('show');
  //         // }
  //         // else{
  //         //   this.router.navigate(['/client'])
  //         // }

  //         // this.router.navigate(['/client'])
  //       }
  //     }
  //   }, error => {
  //     //  console.log(error.error.message);
  //   })
  // }

  // otpsubmit() {
  //   let otpvalue = this.otpform.value.one + this.otpform.value.two + this.otpform.value.three + this.otpform.value.four;
  //   const mobilevalue = this.loginForm.value.check == 'Phone' ? this.loginForm.value.email : ''   // one field is used for both field email and password

  //   let data = {
  //     mobile: mobilevalue,
  //     mobileCountryCode: '+' + this.countryCode1,
  //     otp: otpvalue
  //   }
  //   this.http.post(this.apiList.influncerotp, data).subscribe((res: any) => {
  //     console.log(res)
  //     if (res.statusCode == 200) {
  //       localStorage.setItem('token', res.data.token)
  //       $('#otp').modal('hide');
  //       $('#otp_success').modal('show');
  //       this.messgae.success(res.message)
  //     } else {
  //       //alert(res.message)
  //     }
  //   }, error => {
  //     //alert(error.error.message)

  //   })
  // }

  //  // for  otp talent
  //  otpsubmitTalent() {
  //   console.log('login Otp Submit')
  //   let otpvalue = this.otpform.value.one + this.otpform.value.two + this.otpform.value.three + this.otpform.value.four;
  //   const mobilevalue = this.loginForm.value.check == 'Phone' ? this.loginForm.value.email : ''   // one field is used for both field email and password

  //   let data = {
  //     mobile: mobilevalue,
  //     mobileCountryCode: '+' + this.countryCode1,
  //     otp: otpvalue
  //   }
  //   this.http.post(this.apiList.talentotp, data).subscribe((res: any) => {
  //     console.log(res)
  //     if (res.statusCode == 200) {
  //       localStorage.setItem('token', res.data.token)
  //       $('#otp').modal('hide');
  //       $('#otp_success').modal('show');
  //       this.messgae.success(res.message)

  //     } else {
  //       //alert(res.message)
  //     }
  //   }, error => {
  //     //alert(error.error.message)

  //   })
  // }

  // // for  otp client 

  // otpsubmitClient() {
  //   console.log('login Otp Submit')
  //   let otpvalue = this.otpform.value.one + this.otpform.value.two + this.otpform.value.three + this.otpform.value.four;
  //   const mobilevalue = this.loginForm.value.check == 'Phone' ? this.loginForm.value.email : ''   // one field is used for both field email and password

  //   let data = {
  //     mobile: mobilevalue,
  //     mobileCountryCode: '+' + this.countryCode1,
  //     otp: otpvalue
  //   }
  //   this.http.post(this.apiList.clientotp, data).subscribe((res: any) => {
  //     console.log(res)
  //     if (res.statusCode == 200) {
  //       localStorage.setItem('token', res.data.token)
  //       $('#otp').modal('hide');
  //       $('#otp_success').modal('show');
  //       this.messgae.success(res.message)

  //     } else {
  //       //alert(res.message)
  //     }
  //   }, error => {
  //     //alert(error.error.message)

  //   })
  // }









}