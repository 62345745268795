import { Injectable, ɵbypassSanitizationTrustResourceUrl } from '@angular/core';
import * as io from 'socket.io-client';
import { BehaviorSubject, Subject, ReplaySubject, Observable } from 'rxjs';
import { NavigationEnd, Router } from '@angular/router';
import { environment } from 'src/environments/environment';

export interface Iscallended {
  ended: boolean;
  room: string;
}

@Injectable({
  providedIn: 'root'
})
export class WebSocketService {
  concat(messages: any) {
    throw new Error('Method not implemented.');
  }
  socket;
  public isTypingSubject = new Subject();
  public notificationCount = new BehaviorSubject<any[]>([]);
  public talentUserChatList = new BehaviorSubject<any[]>([]);
  public resetUserSession = new BehaviorSubject(false);
 public artistUserChatList = new BehaviorSubject<any[]>([]);
  public OnlineArtistUsers = new Subject<any>();
  public OnlineAgencyUsers = new Subject<any>();
  public onReciveMessage = new BehaviorSubject([]);
  public onReciveSingleMessage = new BehaviorSubject({});
  public roomIsUpdatedData=new BehaviorSubject({});
  

  public userJoined = new BehaviorSubject({});
  public userCancelled = new BehaviorSubject({});
  
  public unReadCount = new Subject<any>();
  public isUserTyping = new Subject<any>();
  public isOldMessage = new Subject<any>();

  constructor( private router: Router) {
    this.router.events.subscribe((e) => {
      if (localStorage.user) {
        if (e instanceof NavigationEnd) {
           this.joinUser();
        }
      }

      if (localStorage.token) {
        if (e instanceof NavigationEnd) {
  
          this.getSocket().on('isTyping', (data) => {  
          this.isUserTyping.next(data);
    });


this.getSocket().on('allRooms',(data)=>{

  console.log(JSON.stringify(data))
  console.log(data.allRooms)
 this.talentUserChatList.next(data.allRooms);
  console.log("inside join room agencydetails");
  console.log(this.talentUserChatList)
})

this.getSocket().on('roomIsUpdated',(data)=>{
  console.log("inside roomIsUpdated");
  console.log(JSON.stringify(data))

  this.roomIsUpdatedData.next(data);
});

this.getSocket().on('receivedmessage', (data) => {
  console.log(data)
  console.log("inside recieve single"+JSON.stringify(data));
  this.onReciveSingleMessage.next(data);
});

this.getSocket().on('allmessages', (data) => {
  console.log(data)
  console.log("inside recieve"+JSON.stringify(data));
  this.onReciveMessage.next(data.messages);
});


console.log("connected socket");

        }
      }

    });
   
  }
  joinUser() {
    // console.log("inside joinuser");
    // return this.getSocket().emit('join', {});
  }

  roomseen(id: string) {
    console.log(id)
    return this.getSocket().emit('roomSeen', { room : id });
  }

 refreshRooms() {
    console.log("inside refresh room");
    return this.getSocket().emit('refreshRooms', {});
  }
  joinChat(userId: any, to: string, usertype: any, room: string) {
    return this.getSocket().emit('join', { userId, to, userType: usertype, room });
  }
  onJoinAgency(room: any) {
    console.log(room)
    return this.getSocket().emit('enterARoom', { room:room });
  }

  getMessage(room: any,page:any) {
    console.log(room)
    return this.getSocket().emit('getMessage', { room:room, page:page });
  }
 
sendMessage(to,msg: string,type,room) {
    return this.getSocket().emit('sendMessage', { to : to,messageContent: msg, type:type, room:room });
  }
 
 onTypingUser() {
    this.getSocket().emit('isTyping');
  }

  onStartTypingUser(room) {
    console.log(room)
    this.getSocket().emit('startTyping', { room:room });
  }

  onStopTypingUser(room) {
    console.log(room)
    this.getSocket().emit('stopTyping', { room:room });
  }

  disconnectConnection(){
    console.log("inside disconnect");
    this.socket=null;
    return this.getSocket().emit('disconnect');
  }

  
  setupSocketConnection() {
    if (localStorage.token) {
     
      this.getSocket().on('roomIsUpdated',(data)=>{
  console.log("inside roomIsUpdated");
  this.roomIsUpdatedData.next(data);
});

this.getSocket().on('receivedmessage', (data) => {
  console.log("inside recieve single"+JSON.stringify(data));
  this.onReciveSingleMessage.next(data);
});

this.getSocket().on('allmessages', (data) => {
  console.log(data)
  console.log("inside recieve"+JSON.stringify(data.messages));
  this.onReciveMessage.next(data.messages);
});

this.getSocket().on('allRooms', (data) => {
this.artistUserChatList.next(data.rooms);
});



console.log("connected socket");

}

 }

  getSocket() {
    var token=localStorage.getItem("token");
 
    if(token){
      if(!this.socket){
        this.socket = io(environment.CurrentSocketServer,{
          reconnection:true,reconnectionAttempts:50,reconnectionDelay:500,enabledTransports: ["ws", "wss"],
          
          query: {
            token:token
          }
        });
      }
      return this.socket;
    }
    
  }


}
