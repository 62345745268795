<section class="privacy-sec">
    <div class="container">
        <!-- Tabs -->
        <div class="cmn-card mb-4">
            <h3 class="fs20 fw_normal text_color_pink mb-3">Privacy policy</h3>
            <p class="fs16 fw_normal text_color_lightgrey mb-3">We respect your privacy and are committed to protecting it through our compliance with this privacy policy (“Policy”). This Policy describes the types of information we may collect from you or that you may provide (“Personal Information”) on the BooMe  website (“Website”), “BooMe” mobile application (“Mobile Application”), and any of their related products and services (collectively, “Services”), and our practices for collecting, using, maintaining, protecting, and disclosing that Personal Information. It also describes the choices available to you regarding our use of your Personal Information and how you can access and update it. </p>
            <!-- <p class="fs16 fw_normal text_color_lightgrey mb-3">posuere ut ligula ac, efficitur lacinia nisi. Donec vel faucibus erat. Mauris molestie efficitur neque quis pulvinar. Ut malesuada vehicula rutrum. Quisque ligula dui, tempor vitae ultricies sed, egestas quis lacus. Duis bibendum enim at fringilla imperdiet. Suspendisse ligula justo, ullamcorper sed posuere hendrerit, rhoncus vel justo. Phasellus convallis viverra finibus.</p>
            <p class="fs16 fw_normal text_color_lightgrey mb-3">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras blandit justo ac lacinia sollicitudin. Quisque faucibus, neque quis ornare volutpat, quam erat volutpat tellus, quis placerat mi turpis ac sapien. Nulla consectetur ex a faucibus viverra. Nulla malesuada nisl eget dolor sagittis cursus. Sed nibh orci,Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras blandit justo ac lacinia sollicitudin. Quisque faucibus, neque quis ornare volutpat, quam erat volutpat tellus, quis placerat mi turpis ac sapien. Nulla consectetur ex a faucibus viverra. Nulla malesuada nisl eget dolor sagittis cursus. Sed nibh orci,Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras blandit justo ac lacinia sollicitudin. Quisque faucibus, neque quis ornare volutpat, quam erat volutpat tellus, quis placerat mi turpis ac sapien. Nulla consectetur ex a faucibus viverra. Nulla malesuada nisl eget dolor sagittis cursus. Sed nibh orci,</p> -->
            <h3 class="fs20 fw_normal text_color_pink mb-3">Automatic collection of information</h3>
            <p class="fs16 fw_normal text_color_lightgrey mb-3">When you open the Website or use the Mobile Application, our servers automatically record information that your browser or device sends. This data may include information such as your device’s IP address and location, browser and device name and version, operating system type and version, language preferences, the webpage you were visiting before you came to the Services, pages of the Services that you visit, the time spent on those pages, the information you search for on the Services, access times and dates, and other statistics.</p>
<h3 class="fs20 fw_normal text_color_pink mb-3">Collection of personal information</h3>
<p  class="fs16 fw_normal text_color_lightgrey mb-3">You can access and use the Services without telling us who you are or revealing any information by which someone could identify you as a specific, identifiable individual. If, however, you wish to use some of the features offered on the Services, you may be asked to provide certain Personal Information (for example, your name and e-mail address).</p>
            <!-- <ul class="m-0 p-0 list-unstyled">
                <li class="fs16 fw_normal text_color_lightgrey mb-3 position-relative">posuere ut ligula ac, efficitur lacinia nisi. Donec vel faucibus erat. Mauris molestie efficitur neque quis pulvinar. Ut malesuada vehicula rutrum. Quisque ligula dui, tempor vitae ultricies sed, egestas quis</li>
                <li class="fs16 fw_normal text_color_lightgrey mb-3 position-relative">posuere ut ligula ac, efficitur lacinia nisi. Donec vel faucibus erat. Mauris molestie efficitur neque quis pulvinar. Ut malesuada vehicula rutrum. Quisque ligula dui, tempor vitae ultricies sed, egestas quis</li>
                <li class="fs16 fw_normal text_color_lightgrey mb-3 position-relative">posuere ut ligula ac, efficitur lacinia nisi. Donec vel faucibus erat. Mauris molestie efficitur neque quis pulvinar. Ut malesuada vehicula rutrum. Quisque ligula dui, tempor vitae ultricies sed, egestas quis</li>
            </ul> -->
        </div>
    </div>
</section>