import { Component, OnInit } from '@angular/core';
import { ApiListService } from 'src/app/services/Api/api-list.service';
import { HttpFunctionListService } from 'src/app/services/Http/http-function-list.service';
import { CommonClientService } from '../../CommonClientService/common-client.service';

@Component({
  selector: 'app-client-talent-setting-sidebar',
  templateUrl: './client-talent-setting-sidebar.component.html',
  styleUrls: ['./client-talent-setting-sidebar.component.css']
})
export class ClientTalentSettingSidebarComponent implements OnInit {
  clientData: any;


  constructor( 
   
  public ClientInfluncerFunction: CommonClientService ,  public apiList: ApiListService, public http: HttpFunctionListService) {}

  ngOnInit(): void {
      this.ClientInfluncerFunction.clientData.subscribe((res: any) => {
        this.clientData = res
        console.log( this.clientData?.user?.profileImage);
      })
  }

}
