<section class="features position-relative common_padding">
    <div class="container-fluid">
        <div class="row align-items-center">
            <div class="col-lg-6">
                <div class="common_poly_bg">
                    <div class="row">
                        <div class="col-lg-6">
                            <div class="features_card_container">
                                <div class="card text-center p-4">
                                    <img src="assets/images/search_talent.svg" class="mx-auto" alt="">
                                    <div class="card-body">
                                        <h4 class="card-title text_color_pink">Search Talent</h4>
                                        <p class="card-text fs18">Lorem ipsum dolor sit amet consectetur adipiscing
                                            elit.</p>
                                    </div>
                                </div>
                            </div>

                            <div class="features_card_container mt-5">
                                <div class="card text-center p-4">
                                    <img src="assets/images/get_paid.svg" class="mx-auto" alt="">
                                    <div class="card-body">
                                        <h4 class="card-title text_color_pink">Get Paid</h4>
                                        <p class="card-text fs18">Lorem ipsum dolor sit amet consectetur adipiscing
                                            elit.</p>
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div class="col-lg-6 mt-5">
                            <div class="features_card_container">
                                <div class="card text-center p-4">
                                    <img src="assets/images/promote_business.svg" class="mx-auto" alt="">
                                    <div class="card-body">
                                        <h4 class="card-title text_color_pink">Promote Business</h4>
                                        <p class="card-text fs18">Lorem ipsum dolor sit amet consectetur adipiscing
                                            elit.</p>
                                    </div>
                                </div>
                            </div>

                            <div class="features_card_container mt-5">
                                <div class="card text-center p-4">
                                    <img src="assets/images/marketing.svg" class="mx-auto" alt="">
                                    <div class="card-body">
                                        <h4 class="card-title text_color_pink">Marketing</h4>
                                        <p class="card-text fs18">Lorem ipsum dolor sit amet consectetur adipiscing
                                            elit.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
            <div class="col-lg-6">
                <div class="features_content_container position-relative">
                    <h3 class="display-6 position-relative d-inline-block">Features We Have <i><img
                                src="assets/images/features_lines.svg" alt=""></i></h3>
                    <h4 class="fst-italic h4 fw_semibold mt-3 text_color_lightgrey">Lorem ipsum dolor sit amet
                        consectetur adipiscing elit. Mauris ullamcorper aliquam</h4>
                    <div class="features_content mt-4">
                        <p class="fs18">Lorem ipsum dolor sit amet consectetur adipiscing elit. Mauris ullamcorper
                            aliquam risus et condimentum. Duis at imperdiet enim. Lorem ipsum dolor sit amet
                            consectetur adipiscing elit. Mauris ullamcorper aliquam risus et condimentum.
                            Duis at imperdiet enim. Lorem ipsum dolor sit amet consectetur adipiscing elit. Mauris
                            ullamcorper aliquam risus et condimentum. Duis at imperdiet enim.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
