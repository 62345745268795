<section class="privacy-sec">
    <div class="container">
        <!-- Tabs -->
        <div class="cmn-card mb-4">
            <h3 class="fs20 fw_normal text_color_pink mb-3">Terms and conditions</h3>
            <p class="fs16 fw_normal text_color_lightgrey mb-3">These terms and conditions (“Agreement”) set forth the
                general terms and conditions of your use of the BooMe website (“Website”), “BooMe” mobile application
                (“Mobile Application”) and any of their related products and services (collectively, “Services”). This
                Agreement is legally binding between you (“User”, “you” or “your”) and WOLF MANAGEMENT MARKETING
                SERVICES VIA SOCIAL MEDIA (“WOLF MANAGEMENT MARKETING SERVICES VIA SOCIAL MEDIA”, “we”, “us” or “our”).
                If you are entering into this agreement on behalf of a business or other legal entity, you represent
                that you have the authority to bind such entity to this agreement, in which case the terms “User”, “you”
                or “your” shall refer to such entity. If you do not have such authority, or if you do not agree with the
                terms of this agreement, you must not accept this agreement and may not access and use the Services. By
                accessing and using the Services, you acknowledge that you have read, understood, and agree to be bound
                by the terms of this Agreement. You acknowledge that this Agreement is a contract between you and WOLF
                MANAGEMENT MARKETING SERVICES VIA SOCIAL MEDIA, even though it is electronic and is not physically
                signed by you, and it governs your use of the Services.</p>

            <!-- <p class="fs16 fw_normal text_color_lightgrey mb-3">posuere ut ligula ac, efficitur lacinia nisi. Donec vel faucibus erat. Mauris molestie efficitur neque quis pulvinar. Ut malesuada vehicula rutrum. Quisque ligula dui, tempor vitae ultricies sed, egestas quis lacus. Duis bibendum enim at fringilla imperdiet. Suspendisse ligula justo, ullamcorper sed posuere hendrerit, rhoncus vel justo. Phasellus convallis viverra finibus.</p>
            <p class="fs16 fw_normal text_color_lightgrey mb-3">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras blandit justo ac lacinia sollicitudin. Quisque faucibus, neque quis ornare volutpat, quam erat volutpat tellus, quis placerat mi turpis ac sapien. Nulla consectetur ex a faucibus viverra. Nulla malesuada nisl eget dolor sagittis cursus. Sed nibh orci,Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras blandit justo ac lacinia sollicitudin. Quisque faucibus, neque quis ornare volutpat, quam erat volutpat tellus, quis placerat mi turpis ac sapien. Nulla consectetur ex a faucibus viverra. Nulla malesuada nisl eget dolor sagittis cursus. Sed nibh orci,Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras blandit justo ac lacinia sollicitudin. Quisque faucibus, neque quis ornare volutpat, quam erat volutpat tellus, quis placerat mi turpis ac sapien. Nulla consectetur ex a faucibus viverra. Nulla malesuada nisl eget dolor sagittis cursus. Sed nibh orci,</p> -->
            <h3 class="fs20 fw_normal text_color_pink mb-3">Accounts and membership</h3>
            <p class="fs16 fw_normal text_color_lightgrey mb-3">If you create an account on the Services, you are
                responsible for maintaining the security of your account and you are fully responsible for all
                activities that occur under the account and any other actions taken in connection with it. We may
                monitor and review new accounts before you may sign in and start using the Services. Providing false
                contact information of any kind may result in the termination of your account. You must immediately
                notify us of any unauthorized uses of your account or any other breaches of security. We will not be
                liable for any acts or omissions by you, including any damages of any kind incurred as a result of such
                acts or omissions. We may suspend, disable, or delete your account (or any part thereof) if we determine
                that you have violated any provision of this Agreement or that your conduct or content would tend to
                damage our reputation and goodwill. If we delete your account for the foregoing reasons, you may not
                re-register for our Services. We may block your email address and Internet protocol address to prevent
                further registration.</p>
            <h3 class="fs20 fw_normal text_color_pink mb-3">User content</h3>
            <p class="fs16 fw_normal text_color_lightgrey mb-3">We do not own any data, information or material
                (collectively, “Content”) that you submit on the Services in the course of using the Service. You shall
                have sole responsibility for the accuracy, quality, integrity, legality, reliability, appropriateness,
                and intellectual property ownership or right to use of all submitted Content. We may, but have no
                obligation to, monitor and review the Content on the Services submitted or created using our Services by
                you. You grant us permission to access, copy, distribute, store, transmit, reformat, display and perform
                the Content of your user account solely as required for the purpose of providing the Services to you.
                Without limiting any of those representations or warranties, we have the right, though not the
                obligation, to, in our own sole discretion, refuse or remove any Content that, in our reasonable
                opinion, violates any of our policies or is in any way harmful or objectionable. You also grant us the
                license to use, reproduce, adapt, modify, publish or distribute the Content created by you or stored in
                your user account for commercial, marketing or any similar purpose.</p>
            <h3 class="fs20 fw_normal text_color_pink mb-3">Billing and payments</h3>
            <p class="fs16 fw_normal text_color_lightgrey mb-3">You shall pay all fees or charges to your account in
                accordance with the fees, charges, and billing terms in effect at the time a fee or charge is due and
                payable. Where Services are offered on a free trial basis, payment may be required after the free trial
                period ends, and not when you enter your billing details (which may be required prior to the
                commencement of the free trial period). If auto-renewal is enabled for the Services you have subscribed
                for, you will be charged automatically in accordance with the term you selected. Sensitive and private
                data exchange happens over a SSL secured communication channel and is encrypted and protected with
                digital signatures, and the Services are also in compliance with PCI vulnerability standards in order to
                create as secure of an environment as possible for Users. Scans for malware are performed on a regular
                basis for additional security and protection. If, in our judgment, your purchase constitutes a high-risk
                transaction, we will require you to provide us with a copy of your valid government-issued photo
                identification, and possibly a copy of a recent bank statement for the credit or debit card used for the
                purchase. We reserve the right to change products and product pricing at any time. We also reserve the
                right to refuse any order you place with us. We may, in our sole discretion, limit or cancel quantities
                purchased per person, per household or per order. These restrictions may include orders placed by or
                under the same customer account, the same credit card, and/or orders that use the same billing and/or
                shipping address. In the event that we make a change to or cancel an order, we may attempt to notify you
                by contacting the e-mail and/or billing address/phone number provided at the time the order was made.
            </p>
            <h3 class="fs20 fw_normal text_color_pink mb-3">Accuracy of information</h3>
            <p class="fs16 fw_normal text_color_lightgrey mb-3">Occasionally there may be information on the Services
                that contains typographical errors, inaccuracies or omissions that may relate to promotions and offers.
                We reserve the right to correct any errors, inaccuracies or omissions, and to change or update
                information or cancel orders if any information on the Services or Services is inaccurate at any time
                without prior notice (including after you have submitted your order). We undertake no obligation to
                update, amend or clarify information on the Services including, without limitation, pricing information,
                except as required by law. No specified update or refresh date applied on the Services should be taken
                to indicate that all information on the Services or Services has been modified or updated.</p>
            <h3 class="fs20 fw_normal text_color_pink mb-3">Third party services</h3>
            <p class="fs16 fw_normal text_color_lightgrey mb-3">If you decide to enable, access or use third party
                services, be advised that your access and use of such other services are governed solely by the terms
                and conditions of such other services, and we do not endorse, are not responsible or liable for, and
                make no representations as to any aspect of such other services, including, without limitation, their
                content or the manner in which they handle data (including your data) or any interaction between you and
                the provider of such other services. You irrevocably waive any claim against WOLF MANAGEMENT MARKETING
                SERVICES VIA SOCIAL MEDIA with respect to such other services. WOLF MANAGEMENT MARKETING SERVICES VIA
                SOCIAL MEDIA is not liable for any damage or loss caused or alleged to be caused by or in connection
                with your enablement, access or use of any such other services, or your reliance on the privacy
                practices, data security processes or other policies of such other services. You may be required to
                register for or log into such other services on their respective platforms. By enabling any other
                services, you are expressly permitting WOLF MANAGEMENT MARKETING SERVICES VIA SOCIAL MEDIA to disclose
                your data as necessary to facilitate the use or enablement of such other service.</p>

            <h3 class="fs20 fw_normal text_color_pink mb-3">Uptime guarantee</h3>
            <p class="fs16 fw_normal text_color_lightgrey mb-3">We offer a Service uptime guarantee of 99% of available
                time per month. The service uptime guarantee does not apply to service interruptions caused by: (1)
                periodic scheduled maintenance or repairs we may undertake from time to time; (2) interruptions caused
                by you or your activities; (3) outages that do not affect core Service functionality; (4) causes beyond
                our control or that are not reasonably foreseeable; and (5) outages related to the reliability of
                certain programming environments.</p>
            <h3 class="fs20 fw_normal text_color_pink mb-3">Backups</h3>
            <p class="fs16 fw_normal text_color_lightgrey mb-3">We perform regular backups of the Website and its
                Content, however, these backups are for our own administrative purposes only and are in no way
                guaranteed. You are responsible for maintaining your own backups of your data. We do not provide any
                sort of compensation for lost or incomplete data in the event that backups do not function properly. We
                will do our best to ensure complete and accurate backups, but assume no responsibility for this duty.
            </p>
            <h3 class="fs20 fw_normal text_color_pink mb-3">Advertisements</h3>
            <p class="fs16 fw_normal text_color_lightgrey mb-3">Although the Services may link to other resources (such
                as websites, mobile applications, etc.), we are not, directly or indirectly, implying any approval,
                association, sponsorship, endorsement, or affiliation with any linked resource, unless specifically
                stated herein. Some of the links on the Services may be “affiliate links”. This means if you click on
                the link and purchase an item, WOLF MANAGEMENT MARKETING SERVICES VIA SOCIAL MEDIA will receive an
                affiliate commission. We are not responsible for examining or evaluating, and we do not warrant the
                offerings of, any businesses or individuals or the content of their resources. We do not assume any
                responsibility or liability for the actions, products, services, and content of any other third parties.
                You should carefully review the legal statements and other conditions of use of any resource which you
                access through a link on the Services. Your linking to any other off-site resources is at your own risk.
            </p>
            <h3 class="fs20 fw_normal text_color_pink mb-3">Links to other resources</h3>
            <p class="fs16 fw_normal text_color_lightgrey mb-3">If you decide to enable, access or use third party
                services, be advised that your access and use of such other services are governed solely by the terms
                and conditions of such other services, and we do not endorse, are not responsible or liable for, and
                make no representations as to any aspect of such other services, including, without limitation, their
                content or the manner in which they handle data (including your data) or any interaction between you and
                the provider of such other services. You irrevocably waive any claim against WOLF MANAGEMENT MARKETING
                SERVICES VIA SOCIAL MEDIA with respect to such other services. WOLF MANAGEMENT MARKETING SERVICES VIA
                SOCIAL MEDIA is not liable for any damage or loss caused or alleged to be caused by or in connection
                with your enablement, access or use of any such other services, or your reliance on the privacy
                practices, data security processes or other policies of such other services. You may be required to
                register for or log into such other services on their respective platforms. By enabling any other
                services, you are expressly permitting WOLF MANAGEMENT MARKETING SERVICES VIA SOCIAL MEDIA to disclose
                your data as necessary to facilitate the use or enablement of such other service.</p>
            <h3 class="fs20 fw_normal text_color_pink mb-3">Prohibited uses</h3>
            <p class="fs16 fw_normal text_color_lightgrey mb-3">In addition to other terms as set forth in the
                Agreement, you are prohibited from using the Services or Content: (a) for any unlawful purpose; (b) to
                solicit others to perform or participate in any unlawful acts; (c) to violate any international,
                federal, provincial or state regulations, rules, laws, or local ordinances; (d) to infringe upon or
                violate our intellectual property rights or the intellectual property rights of others; (e) to harass,
                abuse, insult, harm, defame, slander, disparage, intimidate, or discriminate based on gender, sexual
                orientation, religion, ethnicity, race, age, national origin, or disability; (f) to submit false or
                misleading information; (g) to upload or transmit viruses or any other type of malicious code that will
                or may be used in any way that will affect the functionality or operation of the Services, third party
                products and services, or the Internet; (h) to spam, phish, pharm, pretext, spider, crawl, or scrape;
                (i) for any obscene or immoral purpose; or (j) to interfere with or circumvent the security features of
                the Services, third party products and services, or the Internet. We reserve the right to terminate your
                use of the Services for violating any of the prohibited uses.</p>

            <!-- <ul class="m-0 p-0 list-unstyled">
                <li class="fs16 fw_normal text_color_lightgrey mb-3 position-relative">posuere ut ligula ac, efficitur lacinia nisi. Donec vel faucibus erat. Mauris molestie efficitur neque quis pulvinar. Ut malesuada vehicula rutrum. Quisque ligula dui, tempor vitae ultricies sed, egestas quis</li>
                <li class="fs16 fw_normal text_color_lightgrey mb-3 position-relative">posuere ut ligula ac, efficitur lacinia nisi. Donec vel faucibus erat. Mauris molestie efficitur neque quis pulvinar. Ut malesuada vehicula rutrum. Quisque ligula dui, tempor vitae ultricies sed, egestas quis</li>
                <li class="fs16 fw_normal text_color_lightgrey mb-3 position-relative">posuere ut ligula ac, efficitur lacinia nisi. Donec vel faucibus erat. Mauris molestie efficitur neque quis pulvinar. Ut malesuada vehicula rutrum. Quisque ligula dui, tempor vitae ultricies sed, egestas quis</li>
            </ul> -->
        </div>
    </div>
</section>