import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import { catchError, tap, retryWhen, mergeMap, delay } from 'rxjs/operators';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';

const maxRetries = 2;
const delayMs = 1000;

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {

  constructor(
    private router: Router,
    private toastr: ToastrService
  ) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      retryWhen(errors =>
        errors.pipe(
          mergeMap((error, index) => {
            if (index < maxRetries && (error.status === 0 || error.status === 500)) {
              // Retry for network errors or server errors
              return of(error).pipe(delay(delayMs));
            }
            return throwError(error);
          })
        )
      ),
      tap(
        (event: HttpEvent<any>) => {
          if (event instanceof HttpResponse) {
            // this.toastr.success(event.body.message)
        }
      }
      ),
      catchError((error: HttpErrorResponse) => {
        if (error.status === 0) {
          // Network error handling
          this.toastr.error('Network error: Please check your internet connection or it can be server error.');
        } else {
          switch (error.status) {
            case 400:
              // Handle 400 error
              this.toastr.warning(error.error.message);
              break;
            case 401:
              // Auto logout if 401 response returned from API
              sessionStorage.clear();
              localStorage.clear();
              this.toastr.warning('You have been logged out for security purposes. Please login again.');
              this.router.navigate(['/']);
              break;
            default:
              // Handle other server-side errors
              this.toastr.error(error.error.message || 'An unexpected error occurred.');
              break;
          }
        }
        return throwError(error);
      })
    );
  }
}
