<app-talent-header></app-talent-header>
<section class="brdcrm-sec">
    <div class="container">
        <div class="inner-sec d-flex align-items-center justify-content-between cmn-card">
            <div class="left-sec d-flex align-items-center">
                <a class="d-flex me-3" href=""><img width="23px" src="assets/images/blue_settings.svg"></a>
                <h3 class="m-0 fs20 fw_semibold text_color_darkgrey">Settings</h3>
            </div>
            <div class="right-sec">
                <!-- <p class="fs18 m-0">23 Apr 2021 | 12:30 PM</p> -->
            </div>
        </div>
    </div>
</section>  

<section class="job-detial-sec">
    <div class="container">
        <div class="row">
            <!-- Settings side menu -->
            <div class="col-md-12 col-lg-4">
                <app-talent-settings-sidebar></app-talent-settings-sidebar>      
            </div>
            <div class="col-md-12 col-lg-8">
                <div class="account-details cmn-card mb-4">
                    <h2 class="settings-heading fs22 fw_semibold text_color_blue">Change Password</h2>
                    <form action="" class="pb-0" [formGroup]="SignupData">

                        <div class="mb-3">
                            <label class="form-label">Old Password</label>
                            <input type="password" class="form-control form-control-lg"
                                placeholder="Enter Password" formControlName="oldPassword" />
                                <div *ngIf="!SignupData.controls.oldPassword.valid &&
                                SignupData.controls.oldPassword.dirty" class="error_main_div">
                                        <div *ngIf="SignupData.controls.oldPassword.hasError('required')"
                                            class="error_message">This field is Mandatory !</div> 
                                            <div *ngIf="SignupData.controls.oldPassword.hasError('passwordStrength') && SignupData.controls.oldPassword.value"
                                            class="error_message">Invalid pass !</div>

                                    </div>
                        </div>
                        
                        <!-- <div class="mb-3">
                            <label class="form-label">Old Password</label>
                            <input type="password" class="form-control form-control-lg"
                                placeholder="Enter Password"/>
                        </div> -->
<!--                         
                        <div class="mb-3">
                            <label class="form-label">New Password</label>
                            <input type="password" class="form-control form-control-lg"
                                placeholder="Enter Password"/>
                        </div>
                        <div class="mb-3">
                            <label class="form-label">Confirm Password</label>
                            <input type="password" class="form-control form-control-lg"
                                placeholder="Enter Password"/>
                        </div> -->
                        <div class="mb-3">
                            <label class="form-label">New Password</label>
                            <input type="password" class="form-control form-control-lg"
                                placeholder="Enter Password" formControlName="newPassword" />
                                <div *ngIf="!SignupData.controls.newPassword.valid &&
                                SignupData.controls.newPassword.dirty" class="error_main_div">
                                        <div *ngIf="SignupData.controls.newPassword.hasError('required')"
                                            class="error_message">This field is Mandatory !</div> 
                                            <div *ngIf="SignupData.controls.newPassword.hasError('passwordStrength') && SignupData.controls.newPassword.value"
                                            class="error_message">Invalid pass !</div>

                                    </div>
                        </div>

                        
                        <div class="mb-3">
                            <label class="form-label">Confirm Password</label>
                            <input type="password" class="form-control form-control-lg"
                                placeholder="Confirm Password" formControlName="confirmPassword" />
                                <div *ngIf="!SignupData.controls.confirmPassword.valid &&
                                SignupData.controls.confirmPassword.dirty" class="error_main_div">
                                <div *ngIf="SignupData.controls.confirmPassword.invalid && SignupData.controls.confirmPassword.errors.ConfirmPassword"
                                            class="error_message">Password and confirm password is not match !</div>
                                        <div *ngIf="SignupData.controls.confirmPassword.hasError('required')"
                                            class="error_message">This field is Mandatory !</div>
                                    </div>
                        </div>
                       
                        <div class="mt-4 position-relative  d-flex justify-content-center">
                            <button type="button" class="btn btn-primary" (click) = "saveData()" [disabled]="!SignupData.valid" >Update</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</section>