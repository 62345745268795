
    <footer class="position-relative mt-4">
        <div class="container">
            <div class="row align-items-center justify-content-between py-4">
                <div class="col">
                    <div class="footer_logo">
                        <img src="assets/images/logo.svg" alt="">
                    </div>
                </div>
                <div class="col">
                    <div class="social_media text-center">
                        <ul class="list-unstyled d-inline-flex m-0">
                            <li>
                                <a><img src="assets/images/footer-facebook.svg" alt=""></a>
                            </li>
                            <li>
                                <a><img src="assets/images/footer-insta.svg" alt=""></a>
                            </li>
                            <li>
                                <a><img src="assets/images/footer-tiktok.svg" alt=""></a>
                            </li>
                            <li>
                                <a><img src="assets/images/footer-youtube.svg" alt=""></a>
                            </li>
                        </ul>

                    </div>
                </div>
                <div class="col">
                    <div class="footer_links text-end">
                        <ul class="list-unstyled d-inline-flex m-0">
                            <li class="me-4"><a class="text_color_darkgrey"  [routerLink]="['/terms']"  href="">Terms & Conditions</a></li>
                            <li><a class="text_color_darkgrey"  [routerLink]="['/privacyPolicy']" href="">Privacy Policy</a></li>
                        </ul>
                    </div>
                </div>

            </div>

            <hr class="m-0" />
            <div class="row py-4">
                <div class="col text-center">
                    <p class="m-0">@copyright By <u><a class="">BooMe</a></u>. All Rights Reserved</p>
                </div>
            </div>
        </div>
    </footer>
