<!-- <app-client-header></app-client-header> -->
<section class="user-type">
    <div class="container">
        <div class="cmn-card mt-3 mb-3">
            <div class="row">
                <div class="col-sm-12 col-md-6">
                    <div class="inner-sec influencer me-md-3 pt-0">
                        <div class="img-wrap">
                            <img src="assets/images/influencerimg.png">
                        </div>
                        <div class="d-flex align-items-center justify-content-center">
                            <div class="content-sec flex-1">
                                <h4 class="fs20 fw_normal mb-3">Looking for an </h4>
                                <h3 class="fs40 text_color_pink mb-0">Influencer</h3>
                            </div>
                            <button class="btn p-0 no-shadow" [routerLink]="['/clientInfluncer']"><img src="assets/images/next-pink.svg"></button>
                        </div>
                    </div>
                </div>
                <div class="col-sm-12 col-md-6">
                    <div class="inner-sec talent ms-md-3 pt-0">
                        <div class="img-wrap text-end">
                            <img src="assets/images/talentimg.png">
                        </div>
                        <div class="d-flex align-items-center justify-content-center">
                            <div class="content-sec flex-1">
                                <h4 class="fs20 fw_normal mb-3">Looking for an </h4>
                                <h3 class="fs40 text_color_blue mb-0">Talent</h3>
                            </div>
                            <button class="btn p-0 no-shadow" [routerLink]="['/clientTalent']"><img src="assets/images/next-blue.svg"></button>
                        </div>
                    </div>
                </div>
            </div>
            <!-- <div class="row">
                <div class="col-sm-12 col-md-12 col-lg-6 mx-auto">
                    <p class="fs14 fw_light text-center mb-0 mt-4">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam a cursus purus, interdum dapibus sem. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam a cursus purus, interdum dapibus sem.</p>
                </div>
            </div> -->
        </div>
    </div>
</section>
