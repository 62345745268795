<div class="intro text-center">

    <img src="assets/images/logo.svg" alt="">

    <p class="m-0 py-4">
        Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has
        been the industry's standard dummy text ever since the 1500s
    </p>
    <div class="social_media">
        <ul class="list-unstyled d-inline-flex m-0">
            <li>
                <a><img src="assets/images/footer-facebook.svg" alt=""></a>
            </li>
            <li>
                <a><img src="assets/images/footer-insta.svg" alt=""></a>
            </li>
            <li>
                <a><img src="assets/images/footer-tiktok.svg" alt=""></a>
            </li>
            <li>
                <a><img src="assets/images/footer-youtube.svg" alt=""></a>
            </li>
        </ul>

        <div class="back_home pt-4">
            <a [routerLink]="['/']" class="text_color_lightgrey"><img src="assets/images/app/arrow_left_back_home.svg" alt=""> Back To Home</a>
        </div>

    </div>
</div>
