import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { ApiListService } from '../Api/api-list.service';
import { BehaviorSubject } from 'rxjs';
import * as moment from 'moment';
import { MatSnackBar } from '@angular/material/snack-bar';

import { HttpFunctionListService } from '../Http/http-function-list.service';

@Injectable({
  providedIn: 'root'
})
export class CommonFunctionService {
  fileDetail: any;
  image: any;
  detail: any;
  signUpData: any;
  hireData:any
  subject = new BehaviorSubject("0");
  public images = new Subject<any>();




  constructor(public apiList: ApiListService, public http: HttpFunctionListService,private snackBar: MatSnackBar) {
    // this.getData()
  //  this.getInfo()
  }
  private fooSubject = new Subject<any>();

  TransferData(filedata: any) {
    this.fileDetail = filedata
  }

  returnData() {
    return this.fileDetail
  }

  selectImage(image: any) {
    this.image = image
  }

  returnImage() {
    return this.image
  }

  /* event for profile update */
  publishSomeData(data: any) {
    console.log(data)
    this.fooSubject.next(data);
  }

  getObservable(): Subject<any> {
    return this.fooSubject;
  }

  /* client data for signup */

  getSignupData(data: any) {
    this.signUpData = data;
  }

  returnSignupData(){
    return this.signUpData
  }


  /* Put method for upload image */
   putMethodUploadImage(api:any,headerType:any, file:any) {
    fetch(api, {
      method: 'put',
      headers: {
        'Content-Type': headerType,
      },
      body: file
    }).then(response => {
      console.log(response)
    }).then(jsonResponse => {
       console.log(jsonResponse);

    }).catch(error => {
       console.log(error)
    })
  }

  putMethodUploadImage1(api:any,headerType:any, file:any) {
    return fetch(api, {
      method: 'put',
      headers: {
        'Content-Type': headerType,
      },
      body: file
    })
  }

  getInfo() {
    this.http.get(this.apiList.talnetMyProfile).subscribe((res: any) => {
      if (res.statusCode == 200) {
       res.data.user.dob= moment(res.data.dateOfBirth).format('DD MMMM, yyyy'),
       this.subject.next(res.data)
      }
    }, error => {

    })
  }

// client talent hire now
  getdetails(data: any){
    this.hireData = data;
  }

  returnDetails(){
    return this.hireData
  }

  getSubscription(data: any){
    this.hireData = data;
  }

  returnSubscriptionDetails(){
    return this.hireData
  }

  openSnackBar(content, action, duration,color,ver,hor) {
    console.log("inside message")
    this.snackBar.open(content, action, {
      duration: 3000,
      verticalPosition: ver, // Allowed values are  'top' | 'bottom'
      horizontalPosition: hor ,
      
      panelClass: [color]// Allowed values are 'start' | 'center' | 'end' | 'left' | 'right'
    });
  }

}
