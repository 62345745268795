import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ApiListService } from 'src/app/services/Api/api-list.service';
declare var $: any;
import { AbstractControl, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MessageHandlingService } from 'src/app/services/Common/message-handling.service';


@Component({
  selector: 'app-home-contact-us',
  templateUrl: './home-contact-us.component.html',
  styleUrls: ['./home-contact-us.component.css']
})
export class HomeContactUsComponent implements OnInit {


  getInTouchForm: UntypedFormGroup;


  constructor( public http :HttpClient , public apiList : ApiListService, public messgae: MessageHandlingService,  public formBuilder: UntypedFormBuilder) { }

  ngOnInit(): void {
    $("#what_we_do_video").on('hidden.bs.modal', function (e) {
      $("#what_we_do_video iframe").attr("src", $("#what_we_do_video iframe").attr("src"));
  });

  this.getInTouchForm = this.formBuilder.group({
    email: ['', [Validators.required, this.emailValidator.bind(this)]],
    name: ['', Validators.required],
    message: ['', Validators.required],
  })

  

  }


  inTouch(){
    let req = {
      name : this.getInTouchForm.value.name , 
      email : this.getInTouchForm.value.email, 
      message : this.getInTouchForm.value.message


    }
    this.http.post(this.apiList.intouch, req).subscribe((res:any) => {
      console.log(res);
      if (res.statusCode == 200) {
        this.messgae.success(res.message)
        this.getInTouchForm.reset()
      }
      
    })
  }


  emailValidator(control: UntypedFormControl): { [s: string]: boolean } {
    if (control.value != null) {
      if (!(control.value.toLowerCase().match('^[a-z0-9]+(\.[_a-z0-9]+)+([@{1}])+(\.[a-z0-9-]+)+([.{1}])(\.[a-z]{1,15})$'))) {
        return { invalidEmail: true };
      }
    }
  }



}
