import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-term-condition-app',
  templateUrl: './term-condition-app.component.html',
  styleUrls: ['./term-condition-app.component.css']
})
export class TermConditionAppComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
