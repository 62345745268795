<div class="settings-menu cmn-card mb-4">
    <div class="top-sec d-flex align-items-center justify-content-start mb-3 pb-3">
        <div class="border-wrap me-3">
            <div class="img-wrap">
               <img *ngIf="!clientData?.user?.profileImage" src="assets/images/transaction-user.svg">
               <img *ngIf="clientData?.user?.profileImage" src="{{clientData?.user?.profileImage}}">
                <!-- <img src="assets/images/profile_user.svg"> -->
            </div>
        </div>
        <div class="content-sec">
            <h2 class="fs18 fw_semibold m-0">
                <!-- Josua Csizmadia -->
                {{ clientData?.user?.fullName}}
               </h2>
            <p class="fs14 mb-0 mt-1">Profile Settings</p>
        </div>
     <!-- <div class="border-wrap me-3">
         <div class="img-wrap">
             <img src="assets/images/profile_user.svg">
         </div>
     </div> -->
     <!-- <div class="content-sec">
         <h2 class="fs18 fw_semibold m-0">Josua Csizmadia</h2>
         <p class="fs14 mb-0 mt-1">Profile Settings</p>
     </div> -->
    </div>
    <h3 class="fs20 text_color_pink fw_semibold mb-4">Settings</h3>

    <ul class="s-menu list-unstyled">
     <li class="menu-item" routerLinkActive="active">
         <a class="text-decoration-none d-flex align-items-center justify-content-between" [routerLink]="['/client-settings']">
             <span><img src="assets/images/setting_payment.svg">Payment</span> 
             <svg xmlns="http://www.w3.org/2000/svg" width="7.44" height="13.01" viewBox="0 0 7.44 13.01">
                 <path id="Icon_ionic-ios-arrow-forward" data-name="Icon ionic-ios-arrow-forward" d="M16.444,12.7,11.52,7.78a.926.926,0,0,1,0-1.313.938.938,0,0,1,1.317,0l5.579,5.574a.928.928,0,0,1,.027,1.282l-5.6,5.613a.93.93,0,0,1-1.317-1.313Z" transform="translate(-11.246 -6.196)" fill="#cecece"/>
             </svg>
         </a>
     </li>

     <li class="menu-item" routerLinkActive="active">
         <a class="text-decoration-none d-flex align-items-center justify-content-between" [routerLink]="['/client-edit-social']">
             <span><img src="assets/images/setting_socialaccount.svg">Social Media Accounts</span> 
             <svg xmlns="http://www.w3.org/2000/svg" width="7.44" height="13.01" viewBox="0 0 7.44 13.01">
                 <path id="Icon_ionic-ios-arrow-forward" data-name="Icon ionic-ios-arrow-forward" d="M16.444,12.7,11.52,7.78a.926.926,0,0,1,0-1.313.938.938,0,0,1,1.317,0l5.579,5.574a.928.928,0,0,1,.027,1.282l-5.6,5.613a.93.93,0,0,1-1.317-1.313Z" transform="translate(-11.246 -6.196)" fill="#cecece"/>
             </svg>
         </a>
     </li>

     <li class="menu-item" routerLinkActive="active">
         <a class="text-decoration-none d-flex align-items-center justify-content-between" [routerLink]="['/client-aboutus']">
             <span><img src="assets/images/setting_about.svg">About Us</span> 
             <svg xmlns="http://www.w3.org/2000/svg" width="7.44" height="13.01" viewBox="0 0 7.44 13.01">
                 <path id="Icon_ionic-ios-arrow-forward" data-name="Icon ionic-ios-arrow-forward" d="M16.444,12.7,11.52,7.78a.926.926,0,0,1,0-1.313.938.938,0,0,1,1.317,0l5.579,5.574a.928.928,0,0,1,.027,1.282l-5.6,5.613a.93.93,0,0,1-1.317-1.313Z" transform="translate(-11.246 -6.196)" fill="#cecece"/>
             </svg>
         </a>
     </li>

     <li class="menu-item" routerLinkActive="active">
         <a class="text-decoration-none d-flex align-items-center justify-content-between" [routerLink]="['/client-contactus']">
             <span><img src="assets/images/setting_contact.svg">Contact Us</span> 
             <svg xmlns="http://www.w3.org/2000/svg" width="7.44" height="13.01" viewBox="0 0 7.44 13.01">
                 <path id="Icon_ionic-ios-arrow-forward" data-name="Icon ionic-ios-arrow-forward" d="M16.444,12.7,11.52,7.78a.926.926,0,0,1,0-1.313.938.938,0,0,1,1.317,0l5.579,5.574a.928.928,0,0,1,.027,1.282l-5.6,5.613a.93.93,0,0,1-1.317-1.313Z" transform="translate(-11.246 -6.196)" fill="#cecece"/>
             </svg>
         </a>
     </li>

     <li class="menu-item">
         <a class="text-decoration-none d-flex align-items-center justify-content-between">
             <span><img src="assets/images/setting_rate.svg">Rate App</span> 
             <svg xmlns="http://www.w3.org/2000/svg" width="7.44" height="13.01" viewBox="0 0 7.44 13.01">
                 <path id="Icon_ionic-ios-arrow-forward" data-name="Icon ionic-ios-arrow-forward" d="M16.444,12.7,11.52,7.78a.926.926,0,0,1,0-1.313.938.938,0,0,1,1.317,0l5.579,5.574a.928.928,0,0,1,.027,1.282l-5.6,5.613a.93.93,0,0,1-1.317-1.313Z" transform="translate(-11.246 -6.196)" fill="#cecece"/>
             </svg>
         </a>
     </li>
    </ul>
 </div>
