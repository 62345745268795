import { CUSTOM_ELEMENTS_SCHEMA, NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgCircleProgressModule } from 'ng-circle-progress';
import { InfluncerModuleRoutingModule } from './influncer-module-routing.module';
import { InfluncerheaderComponent } from '../influncerheader/influncerheader.component';
import { ClientInfluncerDetailsComponent } from '../client-influncer-details/client-influncer-details.component';
import { ClientSettingSidebarComponent } from '../client-setting-sidebar/client-setting-sidebar.component';
import { ClientInfluncerMessageComponent } from '../client-influncer-message/client-influncer-message.component';
import { FormsModule,ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { NgxPaginationModule } from 'ngx-pagination';
import { AuthenticationGuard } from 'src/app/authentication.guard';
import { AuthguardServiceService } from 'src/app/authguard-service.service';



@NgModule({
  declarations: [
    InfluncerheaderComponent,
    ClientSettingSidebarComponent,
   // ClientInfluncerMessageComponent,
    
  ],
  imports: [
    CommonModule,
    InfluncerModuleRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    NgxPaginationModule ,
    
    NgCircleProgressModule.forRoot({
      // set defaults here
      radius: 100,
      responsive: true,
    }),
    
  ],
  schemas: [ CUSTOM_ELEMENTS_SCHEMA ] ,
  providers: [
  
    AuthguardServiceService,
  //  AuthenticationGuard ,
    { provide: AuthenticationGuard, useClass: AuthenticationGuard }   ],

  exports:[InfluncerheaderComponent, ClientSettingSidebarComponent,
   // ClientInfluncerMessageComponent,
  ],
 
})
export class InfluncerModuleModule { }
