<section class="what_we_do position-relative common_padding mt-0">
    <div class="container-fluid">
        <div class="row align-items-center justify-content-between">
            <div class="col-lg-6">
                <div class="common_poly_bg">
                    <div class=" position-relative">
                        <h3 class="display-6 position-relative d-inline-flex heading_with_lines">What We Do <i
                                class="d-inline-flex ms-3"><img class="img-fluid" src="assets/images/features_lines.svg"
                                    alt=""></i></h3>
                        <h4 class="fst-italic h4 fw_semibold mt-3 text_color_lightgrey">Lorem ipsum dolor sit amet
                            consectetur adipiscing elit. Mauris ullamcorper aliquam</h4>
                        <div class="listing mt-4">
                            <ul class="fs18 list-unstyled">
                                <li>Lorem ipsum dolor sit amet consectetur adipiscing elit. Mauris ullamcorper
                                    aliquam.</li>
                                <li>Lorem ipsum dolor sit amet consectetur adipiscing elit. Mauris.</li>
                                <li>Lorem ipsum dolor sit amet consectetur adipiscing elit. Mauris ullamcorper
                                    aliquam sit amet consectetur.</li>
                                <li>Lorem ipsum dolor sit amet consectetur adipiscing elit. Mauris ullamcorper
                                    aliquam.</li>
                            </ul>
                        </div>
                    </div>
                </div>

            </div>
            <div class="col-lg-6">
                <div class="video_container position-relative text-end">
                    <img class="img-fluid" src="assets/images/video_img.png" alt="">
                    <a data-bs-toggle="modal" data-bs-target="#what_we_do_video"><img class="video_play_icon"
                            src="assets/images/play_icon.svg" alt=""></a>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- What wo do Section end -->


<!-- ===================== Contact Us Section start=================== -->

<section class="contactUs position-relative common_padding mt-0">
    <div class="container-fluid">
        <div class="row align-items-center">
            <div class="col-lg-6">
                <div class="common_poly_bg">
                    <img class="img-fluid" src="assets/images/contact_img.png" alt="">
                </div>

            </div>
            <div class="col-lg-6">
                <div class="getinTouch position-relative">
                    <h3 class="display-6 position-relative d-inline-flex heading_with_lines">Get In Touch <i
                            class="d-inline-flex ms-3"><img class="img-fluid" src="assets/images/features_lines.svg"
                                alt=""></i></h3>

                    <h4 class="fst-italic h4 fw_semibold mt-3 text_color_lightgrey">Lorem ipsum dolor sit amet
                        consectetur adipiscing elit. Mauris ullamcorper aliquam</h4>
                    <div class="contactForm mt-4">
                        <form [formGroup]="getInTouchForm">

                            <div class="form-floating mb-4">
                                <input type="text" class="form-control form-control-lg" id="floatingName"
                                    formControlName="name" placeholder="Enter Name">
                                <label for="floatingName">Enter Name</label>
                                <div *ngIf="!getInTouchForm.controls.name.valid &&
                                    getInTouchForm.controls.name.dirty" class="error_main_div">
                                    <div *ngIf="getInTouchForm.controls.name.hasError('required')"
                                        class="error_message">This field is Mandatory !</div>
                                </div>



                            </div>
                            <div class="form-floating mb-4">
                                <input type="email" class="form-control form-control-lg" id="floatingEmail"
                                    formControlName="email" placeholder="Enter email">
                                <label for="floatingEmail">Enter Email</label>

                                <div *ngIf="!getInTouchForm.controls.email.valid &&
                                getInTouchForm.controls.email.dirty">
                                    <div *ngIf="getInTouchForm.controls.email.hasError('invalidEmail') && getInTouchForm.controls.email.value"
                                        class="error_message">Invalid email !</div>
                                    <div *ngIf="getInTouchForm.controls.email.hasError('required')">This field
                                        is
                                        Mandatory !</div>
                                </div>

                            </div>
                            <div class="form-floating">
                                <textarea class="form-control" placeholder="Type Message..." id="floatingTextarea"
                                    formControlName="message" style="height: 170px"></textarea>
                                <label for="floatingTextarea">Type Message...</label>
                                <div *ngIf="!getInTouchForm.controls.message.valid &&
                                getInTouchForm.controls.message.dirty" class="error_main_div">
                                    <div *ngIf="getInTouchForm.controls.message.hasError('required')"
                                        class="error_message">This field is Mandatory !</div>
                                </div>
                            </div>
                            <div class="col-12 mt-5">
                                <button type="submit " (click)="inTouch()" [disabled]="!getInTouchForm.valid"
                                    class="btn btn-primary ">Submit</button>
                            </div>
                        </form>

                    </div>
                </div>
            </div>
        </div>
    </div>
</section>



<div class="modal fade " id="what_we_do_video" tabindex="-1 " aria-hidden="true ">
    <div class="modal-dialog modal-dialog-centered modal-lg ">
        <div class="modal-content ">
            <div class="modal-header ">
                <h4 class="modal-title ">Features We Have</h4>
                <button type="button " class="btn-close " data-bs-dismiss="modal" aria-label="Close "></button>
            </div>
            <div class="modal-body ">
                <div class="embed-responsive embed-responsive-16by9 ">
                    <iframe id="wedo_video " class="embed-responsive-item " width="100% " height="450 "
                        src="//www.youtube.com/embed/YE7VzlLtp-4 " allowfullscreen></iframe>
                </div>
            </div>

        </div>
    </div>
</div>