<section class="about_brief position-relative my-5">
    <div class="container">
        <div class="about_brief_inner text-center">
            <h3 class="display-6 position-relative d-inline-flex heading_with_lines">Brief About Us <i
                    class="d-inline-flex ms-3"><img class="img-fluid" src="assets/images/features_lines.svg" alt=""></i></h3>
            <!-- <h3 class="display-6 position-relative d-inline-block heading_with_lines">Brief About Us</h3> -->
            <h4 class="fst-italic h4 fw_semibold mt-3 text_color_lightgrey">Lorem ipsum dolor sit amet consectetur
                adipiscing elit. Mauris ullamcorper aliquam</h4>

            <div class="about_des mt-5">
                <p class="fs18">Lorem ipsum dolor sit amet consectetur adipiscing elit. Mauris ullamcorper aliquam
                    risus et condimentum. Duis at imperdiet enim. Lorem ipsum dolor sit amet consectetur adipiscing
                    elit. Mauris ullamcorper aliquam risus et condimentum.
                    Duis at imperdiet enim. Lorem ipsum dolor sit amet consectetur adipiscing elit. Mauris
                    ullamcorper aliquam risus et condimentum. Duis at imperdiet enim.</p>

                <div class="download_btns mt-4">
                    <button class="btn button_3d btn_black d-inline-flex align-items-center text-start">
                        <i class="icon_container d-inline-flex align-items-center justify-content-center">
                            <img src="assets/images/apple_icon.svg" alt="" />
                        </i>
                        <span class="ms-3">Download on the <br /> <span class="store_name"> App Store</span></span>
                    </button>

                    <button class="btn button_3d btn_blue d-inline-flex align-items-center text-start ms-4">
                        <i class="icon_container d-inline-flex align-items-center justify-content-center">
                            <img class="ms-1" src="assets/images/playstore.svg" alt="" />
                        </i>
                        <span class="ms-3">Download on the <br /> <span class="store_name"> App Store</span></span>
                    </button>
                </div>
            </div>
        </div>
    </div>
</section>
