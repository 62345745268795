import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { ToastInjector, ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationGuard implements CanActivate {
  constructor(private router: Router,public message: ToastrService) {

  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
      let userSession = localStorage.getItem('token');
      if (userSession) {
        return true;
      }
    else {
      this.unauthorized()
      return this.router.navigate(['/login'])
     
    }
  }

  unauthorized() {
    this.message.error('Please login to Continue.', 'Unauthorized', {
      timeOut: 3000,
      positionClass: 'toast-top-right',
      progressBar: true
    })
    localStorage.clear();
   // this.router.navigate(['/login']);
  
    // this.dataService.changeCartCount(true);
  }
}
