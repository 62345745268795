import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {FormsModule} from "@angular/forms";

import { ClientTalentModuleRoutingModule } from './client-talent-module-routing.module';
import { ClientTalentheaderComponent } from '../client-talentheader/client-talentheader.component';
// import { ClientTalentSettingsComponent } from '../client-talent-settings/client-talent-settings.component';

import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';

import { ClientTalentSettingSidebarComponent } from '../client-talent-setting-sidebar/client-talent-setting-sidebar.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgxPaginationModule } from 'ngx-pagination';
import { AuthenticationGuard } from 'src/app/authentication.guard';
import { AuthguardServiceService } from 'src/app/authguard-service.service';

@NgModule({
  declarations: [
    ClientTalentheaderComponent,
    ClientTalentSettingSidebarComponent,
  ],
  
  imports: [
    CommonModule,
    ClientTalentModuleRoutingModule,
    MatDatepickerModule,
    FormsModule ,
    MatNativeDateModule,
    MatFormFieldModule,
    MatInputModule, 
    NgxPaginationModule ,
      //  MatMomentDateModule,
     NgbModule
  ],
  schemas: [ CUSTOM_ELEMENTS_SCHEMA ] ,
  providers: [
  
    AuthguardServiceService,
  //  AuthenticationGuard ,
    { provide: AuthenticationGuard, useClass: AuthenticationGuard }   ],

  exports:[ClientTalentheaderComponent, ClientTalentSettingSidebarComponent]
})
export class ClientTalentModuleModule { }
