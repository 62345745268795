<section class="banner position-relative d-flex align-items-center vh-100">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-12 col-lg-6">
                <app-left-side-social></app-left-side-social>
            </div>
            <div class="col-12 col-lg-6">
                <div class="card">
                    <div class="position-relative">
                        <div class="card_header text-center px-4 pt-4 pb-0">
                            <h3 class="display-6 position-relative d-inline-flex heading_with_lines">Reset Password<i class="d-inline-flex ms-3"><img class="img-fluid"                  src="assets/images/features_lines.svg" alt=""></i></h3>
                            <h4 class="fst-italic text_color_lightgrey fw_semibold fs20">Set the new password for your account so you can login and access all the features.</h4>
                        </div>
                        <div class="form_container pt-4">
                                <form action="" class="p-4">
                                    <div class="mb-3">
                                        <label class="form-label">New Password</label>
                                        <input type="password" class="form-control form-control-lg"
                                            placeholder="Enter Password" />
                                    </div>
                                    <div class="mb-3">
                                        <label class="form-label">Confirm Password</label>
                                        <input type="password" class="form-control form-control-lg"
                                            placeholder="Confirm Password" />
                                    </div>
                                    <button class="btn btn-primary mt-4 px-4 d-block mx-auto" [routerLink]="['/login']">Save Password</button>
                                </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>