<section class="banner position-relative d-flex align-items-center vh-100">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-12 col-lg-6">
                <app-left-side-social></app-left-side-social>
            </div>
            <div class="col-12 col-lg-6">
                <div class="card verification-crd">
                    <div class="position-relative">
                        <div class="card_header text-center px-4 pt-4 pb-0">
                            <h3 class="display-6 position-relative d-inline-flex">Identity Verification</h3>
                        </div>

                        <div class="vcScroll pb-3" id="vcScroll">
                            <div class="identity-content me-3">
                                <h5 class="fs24 text_color_pink fw_semibold text-center mb-2">Take A selfie</h5>
                                <h4 class="fst-italic text_color_lightgrey fw_semibold fs20 text-center mb-4">Please
                                    provide us with a good <br>photo of yourself.</h4>

                                <div class="scan-img-wrap mx-auto text-center mb-4">
                                    <img *ngIf="!imgURL"  src="assets/images/img-scan.svg">
                                    <img class="img_cover" *ngIf="imgURL" class="" src="{{imgURL}}">
                                    <input type="file" (change)="selectImage($event)">
                                </div>

                                <div class="sug-itm d-flex align-items-center mb-4">
                                    <div class="count-sec me-3 d-flex align-items-center justify-content-center">
                                        <h5 class="fs18 fw_semibold text-white m-0">1</h5>
                                    </div>
                                    <div class="content-sec">
                                        <h3 class="fs18 fw_semibold mb-1">Good Lighting</h3>
                                        <p class="fs16 fw_normal text_color_lightgrey mb-0">Make sure you are in a well
                                            lit area.</p>
                                    </div>
                                </div>
                                <div class="sug-itm d-flex align-items-center mb-4">
                                    <div class="count-sec me-3 d-flex align-items-center justify-content-center">
                                        <h5 class="fs18 fw_semibold text-white m-0">2</h5>
                                    </div>
                                    <div class="content-sec">
                                        <h3 class="fs18 fw_semibold mb-1">Look Straight</h3>
                                        <p class="fs16 fw_normal text_color_lightgrey mb-0">Hold your phone at eye level
                                            and look straight to the camera</p>
                                    </div>
                                </div>
                                <div class="btns-sec">
                                    <button type="button" class="next continuebtn btn btn-primary"
                                        [disabled]="!imgURL"  [routerLink]="['/identity-verify']" >Continue</button>
                                    <button type="button"
                                        class="btn btn-link previous_button text_color_pink float-end next no-shadow ps-2 pe-0"
                                        (click)="skipIdentity('identityVerification')"        [routerLink]="['/connect']" >Skip</button>
                                </div>
                            </div>
                        </div>


                    </div>
                </div>
            </div>
        </div>
    </div>
</section>