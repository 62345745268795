
import { Component, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, FormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ApiListService } from 'src/app/services/Api/api-list.service';
import { CommonFunctionService } from 'src/app/services/Common/common-function.service';
import { MessageHandlingService } from 'src/app/services/Common/message-handling.service';
import { HttpFunctionListService } from 'src/app/services/Http/http-function-list.service';

@Component({
  selector: 'app-talent-change-password',
  templateUrl: './talent-change-password.component.html',
  styleUrls: ['./talent-change-password.component.css']
})
export class TalentChangePasswordComponent implements OnInit {
  SignupData: UntypedFormGroup;

  constructor(public common: CommonFunctionService,
    public messgae: MessageHandlingService, public router: Router, public apiList: ApiListService, public http: HttpFunctionListService, public formBuilder: UntypedFormBuilder) { }

  ngOnInit(): void {

    this.SignupData = this.formBuilder.group({
      oldPassword: ['', Validators.required],
      confirmPassword: ['', Validators.required],
      newPassword: ['', Validators.required],
    }, { validator: this.checkPasswords }
    )
  }


  checkPasswords(control: AbstractControl) {
    const oldpass = control.get('oldPassword').value;
    const pass = control.get('newPassword').value;
    const confirmPass = control.get('confirmPassword').value;;
    if (pass != confirmPass) {
      control.get('confirmPassword').setErrors({ ConfirmPassword: true });
    } else {
      return null;
    }
  }

  saveData() {
    let data = {
      oldPassword: this.SignupData.value.oldPassword,
      newPassword: this.SignupData.value.newPassword,
      confirmPassword: this.SignupData.value.confirmPassword
    }
    this.http.post(this.apiList.changePassword, data).subscribe((res: any) => {
      if (res.statusCode == 200) {
        this.messgae.success(res.message)
        this.SignupData.reset()
      }
    }, error => {

    })
  }

  



}

