import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthenticationGuard } from 'src/app/authentication.guard';


const routes: Routes = [
  {
    path: 'client-talent-messages',
    canActivate:[AuthenticationGuard] ,
    loadChildren: () => import('../../../Client/ClientTalent/client-talent-message/client-talent-message.module').then(m => m.ClientTalentMessageModule)
  },
  {
    path: 'client-talent-bookings',
    canActivate:[AuthenticationGuard] ,
    loadChildren: () => import('../../../Client/ClientTalent/client-talent-bookings/client-talent-bookings.module').then(m => m.ClientTalentBookingsModule)
  },
  {
    path: 'client-talent-privacypolicy',
    canActivate:[AuthenticationGuard] ,
    loadChildren: () => import('../../../Client/ClientTalent/talent-privacy-policy/talent-privacy-policy.module').then(m => m.TalentPrivacyPolicyModule)
  },
  {
    path: 'client-talent-aboutUs',
    canActivate:[AuthenticationGuard] ,
    loadChildren: () => import('../../../Client/ClientTalent/talent-about-us/talent-about-us.module').then(m => m.TalentAboutUsModule)
  },
  {
    path: 'client-talent-contactUs',
    canActivate:[AuthenticationGuard] ,
    loadChildren: () => import('../../../Client/ClientTalent/talent-contact-us/talent-contact-us.module').then(m => m.TalentContactUsModule)
  },
  {
    path: 'client-talent-social-accounts',
    canActivate:[AuthenticationGuard] ,
    loadChildren: () => import('../../../Client/ClientTalent/talent-social-accounts/talent-social-accounts.module').then(m => m.TalentSocialAccountsModule)
  },
  {
    path: 'client-talent-reviews',
    canActivate:[AuthenticationGuard] ,
    loadChildren: () => import('../../../Client/ClientTalent/client-talent-reviews/client-talent-reviews.module').then(m => m.ClientTalentReviewsModule)
  },
  {
    path: 'client-talent-settings',
    canActivate:[AuthenticationGuard] ,
    loadChildren: () => import('../../../Client/ClientTalent/client-talent-settings/client-talent-settings/client-talent-settings.module').then(m => m.ClientTalentSettingsModule)
  },
  {
    path: 'client-talent-details',
    canActivate:[AuthenticationGuard] ,
    loadChildren: () => import('../../../Client/ClientTalent/client-talent-detail/client-talent-detail.module').then(m => m.ClientTalentDetailModule)
  },
  {
    path: 'client-talent-notification',
    canActivate:[AuthenticationGuard] ,
    loadChildren: () => import('../../../Client/ClientTalent/clienttalent-notification/clienttalent-notification.module').then(m => m.ClienttalentNotificationModule)
  },
  {
    path: 'hire-talent',
    canActivate:[AuthenticationGuard] ,
    loadChildren: () => import('../../../Client/ClientTalent/hire-talent/hire-talent.module').then(m => m.HireTalentModule)
  },
  {
    path : 'hire-talent-payment' , 
    canActivate:[AuthenticationGuard] ,
    loadChildren: () => import('../../../Client/ClientTalent/hire-talent-payment/hire-talent-payment.module').then(m => m.HireTalentPaymentModule)
},
  {
    path : 'client-booking-upcoming' , 
    canActivate:[AuthenticationGuard] ,
    loadChildren: () => import('../../../Client/ClientTalent/client-booking-detail-upcoming/client-booking-detail-upcoming.module').then(m => m.ClientBookingDetailUpcomingModule)
},
  {path : 'client-booking-ongoing' , 
  canActivate:[AuthenticationGuard] ,
  loadChildren: () => import('../../../Client/ClientTalent/client-booking-detail-ongoing/client-booking-detail-ongoing.module').then(m => m.ClientBookingDetailOngoingModule)
},
  {
    path : 'client-booking-completed' , 
    canActivate:[AuthenticationGuard] ,
    loadChildren: () => import('../../../Client/ClientTalent/client-booking-detail-completed/client-booking-detail-completed.module').then(m => m.ClientBookingDetailCompletedModule)
  },
  {
    path : 'client-booking-rated' , 
    canActivate:[AuthenticationGuard] ,
    loadChildren: () => import('../../../Client/ClientTalent/client-booking-detail-rated/client-booking-detail-rated.module').then(m => m.ClientBookingDetailRatedModule)
  },
  {
    path : 'client-talent-extra-details' ,
    canActivate:[AuthenticationGuard] ,
   loadChildren: () => import('../../../Client/ClientTalent/client-talent-detail-extra/client-talent-detail-extra.module').then(m => m.ClientTalentDetailExtraModule)
  },
  {
    path : 'client-talent-profile' , 
    canActivate:[AuthenticationGuard] ,
    loadChildren: () => import('../../../Client/ClientTalent/client-talent-profile/client-talent-profile.module').then(m => m.ClientTalentProfileModule)
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ClientTalentModuleRoutingModule { }
