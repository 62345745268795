//import { CompileShallowModuleMetadata } from '@angular/compiler';
import { Component, OnInit } from '@angular/core';
import { Validators } from '@angular/forms';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { ApiListService } from 'src/app/services/Api/api-list.service';
import { MessageHandlingService } from 'src/app/services/Common/message-handling.service';
import { HttpFunctionListService } from 'src/app/services/Http/http-function-list.service';
import { Common_FunctionService } from '../../Pages/CommonInfluncer/common-function.service';
declare var $: any;
declare var verificationForm: any;

@Component({
  selector: 'app-create-account',
  templateUrl: './create-account.component.html',
  styleUrls: ['./create-account.component.css']
})
export class CreateAccountComponent implements OnInit {
  list: any = [];
  intersetForm: UntypedFormGroup
  select_interset: any = [];
  country: any;
  imageUploadData: any;
  headshot: any;
  beauty: any;
  fulllenght: any;
  headshotImage: any;
  beautyImage: any;
  fulllenghtImage: any;
  cities: any;
  allData: string[];
  countryvalue: any;
  fileImage: any;
  putApi: any;
  profileData: any;
  category: any;
  checked: boolean = false;
  data: any;

  // maxNo: any;


  constructor(public messgae: MessageHandlingService, public commonInfluncer:Common_FunctionService, public router:Router,public common: Common_FunctionService,  public apiList: ApiListService, public http: HttpFunctionListService, public formBuilder: UntypedFormBuilder) {
    // this.categoryListing()
    this.intersetForm = this.formBuilder.group({
      bio: [],
      categoryselect: [],

      completeAddress: [],
      postalCode: [],
      city: [],
      country: ['Afghanistan',[Validators.required]],

      check: ['Centimeter',[Validators.required]],
      heightCm: [],
      heightInches: [],
      jeanLength: [],
      jeanWaist: [],
      shoeSize: [],
      shirt: [],
      ethnicity: [],
      hairColor: [],
      hairLength: [],
      eyesColor: [],
      headShotImage: [],
      beautyShotImage: [],
      fullLengthImage: [],

      accountNumber: [],
      ibanNumber: [],
      routingNumber: [],
      swiftCode: [],
      bankName: [],
      bankAddress: [],

    })
    console.log(this.intersetForm.get('check').value)
  }

  ngOnInit(): void {
    new verificationForm
    $('fieldset input[type="radio"]').click(function () {
      var demovalue = $(this).val();
      $(".height").hide();
      $("#show" + demovalue).show();
    });
    this.categoryListing();
    this.countryList();
    this.influencerInfo() ;
    // get interest
    this.common.subject.subscribe((res: any) => {
      this.profileData = res ;
      res?.user?.interests.forEach((element:any) => {
        this.list.forEach((element1:any,index:any) => {
          if(element1._id == element._id ){
            element1.checked = true
            let check = this.select_interset.includes(element._id);
            if(!check){
              this.select_interset.push(element._id)
            }
           
        }else{
          }
        });
        console.log(this.select_interset)
      });

      this.intersetForm.patchValue({
        bio: res?.user?.bio
      })
    
    });
      //bank details 

  this.getBankData()

    // address 
    this.getAddressData();
    this.getpersonalData()
  }


  categoryListing() {
    this.http.get(this.apiList.interestList).subscribe((res: any) => {
  //  this.getDetail();
  this.commonInfluncer.getInfluencerInfo()
      if (res.statusCode == 200) {
        this.list = res.data.interests
        console.log(this.list)
      }
    })
  }

  countryList() {
    this.http.get('assets/countries.json').subscribe((res: any) => {
      this.allData = res
      this.country = Object.keys(this.allData);
      this.getCitieswithcountries(this.intersetForm.value.country)
    })
  }

  getCitieswithcountries(event) {
    if (event.target) {    // first time select first value of array
      this.countryvalue = event.target.value
    } else {
      this.countryvalue = event
    }
    this.cities = Object(this.allData[this.countryvalue]);
    this.intersetForm.patchValue({
      city:this.cities[0]
    })
  }

  // categoryListing() {
  //   this.http.get(this.apiList.interestList).subscribe((res: any) => {
  //     console.log(res)
  //     if (res.statusCode == 200) {
  //       this.list = res.data.interests
  //     }
  //   })
  // }

  submit(){
    if(this.select_interset.length <= 4){
      let data = {
        bio: this.intersetForm.value.bio,
        interests: this.select_interset
      }
    this.http.post(this.apiList.influncerBioInterests, data).subscribe((res: any) => {
      if (res.statusCode == 200) {
        this.messgae.success(res.message)
        // this.commonInfluncer.getInfluencerInfo()
        // this.getDetail()
      }

    })
  }else{
    this.messgae.error('You can select maximum 4 Interests')
  }
  }



  // submit() {
  //   let data = {
  //     bio: this.intersetForm.value.bio,
  //     interests: this.select_interset
  //   }
  //   this.http.post(this.apiList.influncerBioInterests, data).subscribe((res: any) => {
  //     console.log(res)
  //     if(res.statusCode == 200){
  //       this.messgae.success(res.message)
  //     }
  //     else{

  //     }
  //   })
  // }

  submitBankDetail(){
    if(this.intersetForm.valid){
      this.submitBantDetail()
    }
    else{
      this.messgae.error('Please enter account number')
    }
  }


  submitBantDetail() {
    let data = {
      accountNumber: this.intersetForm.value.accountNumber,
      ibanNumber: this.intersetForm.value.ibanNumber,
      routingNumber: this.intersetForm.value.routingNumber,
      swiftCode: this.intersetForm.value.swiftCode,
      bankName: this.intersetForm.value.bankName,
      bankAddress: this.intersetForm.value.bankAddress,
    }
    this.http.post(this.apiList.influncerBankDetails, data).subscribe((res: any) => {
      if(res.statusCode == 200){
        this.messgae.success(res.message)
      }else{

      }
    })
  }

  submitpersonalDetail() {
    let data = {
      heightCm: this.intersetForm.value.heightCm ? this.intersetForm.value.heightCm:'0',
      heightInches: this.intersetForm.value.heightInches ? this.intersetForm.value.heightInches:'0',
      jeanLength: this.intersetForm.value.jeanLength,
      jeanWaist: this.intersetForm.value.jeanWaist,
      shoeSize: this.intersetForm.value.shoeSize,
      shirt: this.intersetForm.value.shirt,
      ethnicity: this.intersetForm.value.ethnicity,
      hairColor: this.intersetForm.value.hairColor,
      hairLength: this.intersetForm.value.hairLength,
      eyesColor: this.intersetForm.value.eyesColor,
      headShotImage: this.headshot,
      beautyShotImage: this.beauty,
      fullLengthImage: this.fulllenght,
    }
    this.http.post(this.apiList.influncerPersonalDetails, data).subscribe((res: any) => {
      if(res.statusCode == 200){
        this.messgae.success(res.message)
        this.router.navigate(['/identity-verification'])
      }else{

      }
    })
  }

  submitpersonal() {
    let data = {
      completeAddress: this.intersetForm.value.completeAddress,
      postalCode: this.intersetForm.value.postalCode,
      city: this.intersetForm.value.city,
      country: this.intersetForm.value.country,
    }
    this.http.post(this.apiList.influncerAddress, data).subscribe((res: any) => {
      if(res.statusCode == 200){
        this.messgae.success(res.message)
      }else{

      }
    })
  }


  imageUpload(type: any) {
    if (this.imageUploadData) {
      let data = {
        key: this.imageUploadData.name,
        contentType: this.imageUploadData.type
      }
      this.http.post(this.apiList.ImageUpload, data).subscribe((res: any) => {
        console.log(res)
        if (res.statusCode == 200) {
          this.putApi = res.data.preSignedUrl;
          if (type == 'headshot') {
            this.headshotImage = res.data.fileName;
          } else if (type == 'beauty') {
            this.beautyImage = res.data.fileName;
          } else if (type == 'fulllenght') {
            this.fulllenghtImage = res.data.fileName;
          }
          this.intersetForm.patchValue({
            headShotImage: this.headshotImage,
            beautyShotImage: this.beautyImage,
            fullLengthImage: this.fulllenghtImage,
          })
          this.putMethodUploadImage()
        } else {

        }
      }, error => {
        console.log(error)

      })
    }

  }

  selectImage(event: any, type: any) {   //type = image type\
    var that = this;
    if (event.target.files && event.target.files[0]) {
      let fileList = event.target.files;
      let file = fileList[0];
      that.fileImage = file;
      let reader = new FileReader();
      reader.readAsDataURL(event.target.files[0]);
      console.log(event.target.files[0])
      this.imageUploadData = event.target.files[0]
      that.imageUpload(type);
      reader.onload = function () {
        if (type == 'headshot') {
          that.headshot = reader.result;
        } else if (type == 'beauty') {
          that.beauty = reader.result;
        } else if (type == 'fulllenght') {
          that.fulllenght = reader.result;
        }
      };
      reader.onerror = function (error) {
        console.log('Error: ', error);
      };
    }
  }

  putMethodUploadImage() {
    fetch(this.putApi, {
      method: 'put',
      headers: {
        'Content-Type': this.imageUploadData.type,
      },
      body: this.fileImage
    }).then(response => {
    }).then(jsonResponse => {
    }).catch(error => {
    })
  }

  select(event: any, value: any) {

    if (event.target.checked) {
      this.select_interset.push(value)
    
    } else {
      let index = this.removeCheckedFromArray(value);
      this.select_interset.splice(index, 1);

    }
    console.log(this.select_interset.length)
    // this.maxNo = this.select_interset.length >= 4 ? true : false
    console.log( this.maxNo)
   // this.select_interset === 4 ? (this.maxNo = true) : (this.maxNo = false);
    console.log( this.select_interset)
  }

  removeCheckedFromArray(checkbox: String) {
    return this.select_interset.findIndex((category) => {
      return category === checkbox;
    })
  }

  check(item, value) {

    if (item.checked) {
      this.select_interset.push(value)
    
    } else {
      let index = this.removeCheckedFromArray(value);
      this.select_interset.splice(index, 1);
    }
    console.log(this.select_interset.length)
    // if (item.checked) {
    // this.checkedNumber++;
    // } else {
    //   this.checkedNumber--;
    // }
  }

  influencerInfo(){
    this.http.get(this.apiList.influncerInfo).subscribe((res:any) => {
      if(res.statusCode == 200){
      this.data = res.data;
      }
    },
    error => {
    })
  } 
  
  checkboxList = [];
  public maxElementCheckbox = 2;
  
  changeSelection($event, value){
  
      if ($event.target.checked) {
        this.select_interset.push(value)
  
        //this.checkboxList.push(id);
      }  else {
        // let index = this.removeCheckedFromArray(value);
        this.select_interset.splice(this.select_interset.indexOf(value), 1);
       // this.checkboxList.splice(this.checkboxList.indexOf(id), 1);
      }
      console.log(this.checkboxList);
    }
  
    public disableCheckbox(id): boolean {
      return this.select_interset.length >= this.maxElementCheckbox && !this.select_interset.includes(id);
    }
    maxNo:boolean
    onChange(isChecked: boolean) {
      if (isChecked) this.select_interset++;
      else this.select_interset--;
      this.select_interset === 4 ? (this.maxNo = true) : (this.maxNo = false);
    }


    getAddressData() {
      this.http.get(this.apiList.influncerSavedAddress).subscribe((res: any) => {
        if (res.statusCode == 200) {
          this.intersetForm.patchValue({
            completeAddress: res.data.address.completeAddress,
            postalCode: res.data.address.postalCode,
            city: res.data.address.city,
            country: res.data.address.country
          })
          this.countryList()
        }
      }, error => {
  
      })
    }


    getBankData() {
      this.http.get(this.apiList.influncerMybankDetail).subscribe((res: any) => {
        console.log(res)
        if (res.statusCode == 200) {
          this.intersetForm.patchValue({
            accountNumber: res.data.user?.accountNumber,
            ibanNumber: res.data.user?.ibanNumber,
            routingNumber: res.data.user?.routingNumber,
            swiftCode: res.data.user?.swiftCode,
            bankName: res.data.user?.bankName,
            bankAddress: res.data.user?.bankAddress
          })
        }
      }, error => {
  
      })
    }

    getpersonalData() {
      this.http.get(this.apiList.influncerMyPersonalDetail).subscribe((res: any) => {
        console.log(res)
        if (res.statusCode == 200) {
         
          this.intersetForm.patchValue({
            radioSelection: (res.data.user?.heightCm != '' && res.data.user?.heightCm != undefined) ? 'Centimeter' : 'Inches',
            heightCm: res.data.user?.heightCm ? res.data.user?.heightCm:'0',
            heightInches: res.data.user?.heightInches ? res.data.user?.heightInches:'0',
            jeanLength: res.data.user?.jeanLength,
            jeanWaist: res.data.user?.jeanWaist,
            shoeSize: res.data.user?.shoeSize,
            shirt: res.data.user?.shirt,
            ethnicity: res.data.user?.ethnicity,
            hairColor: res.data.user?.hairColor,
            hairLength: res.data.user?.hairLength,
            eyesColor: res.data.user?.eyesColor,
          })
          this.headshot = res.data.user?.headShotImage,
            this.beauty = res.data.user?.beautyShotImage,
            this.fulllenght = res.data.user?.fullLengthImage
  
        }
      }, error => {
  
      })
    }

    getInfluencerInfo() {
      this.http.get(this.apiList.influncerMyProfile).subscribe((res: any) => {
        if (res.statusCode == 200) {
        }
      }, error => {
  
      })
    }

    skipBankDetail(page){

      switch(page){
        case 'bankDetail': var pageName = 'bankDetail'; 
        break;
  
        case 'personalDetail': var pageName = 'personalDetail'; 
        break;
  
      }
  
     this.http.get(this.apiList.skipInfluencerScreen+'/' + pageName).subscribe((res:any)=>{
       console.log(res)
     })
    }

    // this.headshot = res.data.user?.headShotImage,
    // this.beautyImage = res.data.user?.beautyShotImage,
    // this.fulllenghtImage = res.data.user?.fullLengthImage,

    // select(event: any, value: any) {
  //   if (event.target.checked) {
  //     this.select_interset.push(value)
  //   } else {
  //     let index = this.removeCheckedFromArray(value);
  //     this.select_interset.splice(index, 1);
  //   }
  // }

  // removeCheckedFromArray(checkbox: String) {
  //   return this.select_interset.findIndex((category) => {
  //     return category === checkbox;
  //   })
  // }
}