<section class="brdcrm-sec">
    <div class="container">
        <div class="inner-sec d-flex align-items-center justify-content-between cmn-card">
            <div class="left-sec d-flex align-items-center">
                <a class="d-flex me-3" href=""><img width="23px" src="assets/images/home_icn.svg"></a>
                <h3 class="m-0 fs20 fw_semibold text_color_darkgrey">Your Task</h3>
            </div>
            <!-- <div class="right-sec">
                <p class="fs18 m-0">23 Apr 2021 | 12:30 PM</p>
            </div> -->
        </div>
    </div>
</section>
