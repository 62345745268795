import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-talent-topbar',
  templateUrl: './talent-topbar.component.html',
  styleUrls: ['./talent-topbar.component.css']
})
export class TalentTopbarComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
