import { Component, OnInit } from '@angular/core';
import { ApiListService } from 'src/app/services/Api/api-list.service';
import { HttpFunctionListService } from 'src/app/services/Http/http-function-list.service';
import { CommonClientService } from '../../Client/CommonClientService/common-client.service';
@Component({
  selector: 'app-talent-settings-sidebar',
  templateUrl: './talent-settings-sidebar.component.html',
  styleUrls: ['./talent-settings-sidebar.component.css']
})
export class TalentSettingsSidebarComponent implements OnInit {
  profileData: any;

  constructor(public apiList: ApiListService ,   public http: HttpFunctionListService) { }

  ngOnInit(): void {
  this.getData()
  }
  getData(){     
    this.http.get(this.apiList.talentMyInfo).subscribe((res:any)=>{
      if (res.statusCode == 200) {
        console.log(res.data)
        this.profileData = res.data;
      }
    },error=>{

    })
  }

}
