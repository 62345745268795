import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiListService } from 'src/app/services/Api/api-list.service';
import { HttpFunctionListService } from 'src/app/services/Http/http-function-list.service';
// const express: any = require('express');
// const express = require('express');
// const app = express();

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {
  code: string;
  access_token: any;
  user_id: any;
  clientID = 'awn2rfp7tpbzf262';
  clientsecretKey = '705fe2f93a7bf94c2d0480a83b4a0eca'
  b:any ; 
  a:any 
  SERVER_ENDPOINT_REDIRECT:any
  loginToken:any
  tiktoktoken: any;
  data: any;
  allData: any;
  open_id: any;
  location: any;

  constructor( private route:ActivatedRoute,
    public router:Router, private httpClient: HttpClient ,
   public apiList: ApiListService, public http: HttpFunctionListService) {
  //  this.token = location.href
  //   console.log(this.token,"location");
    this.route.params.subscribe(params => {
      console.log(params, "urlurl")
      // //debugger
      // this.token = params['code'];
      // this.token =  params['code'];
      // console.log(this.token);
    }); 

    console.log(window.location.href,"pathpath")
    this.a = window.location.href
    console.log(this.a, "this.a");
    
    // //debugger
    this.b = this.a.split('https://boome-wm.com/?code=')[1]
    console.log(this.b , "this.b ");
    
    // //debugger
    this.code = this.b?.split('&scopes=user.info.basic,video.list&state=sdfgd345')[0]
    console.log(this.code,'this.code');
    if(this.code) {
     this.loginToken = localStorage.getItem('token')
    }
   }

  ngOnInit(): void {


    history.pushState(null, null, location.href);
    this.location.onPopState(() => {
      history.pushState(null, null, location.href);
    })
    
    if(this.code) {
      this.instaToken()
    }
    
  }



  instaToken() {
    let data = {
      code: this.code,
   
    }
    this.http.post(this.apiList.tiktok, data).subscribe((res: any) => {
      if (res.statusCode == 200) {
       this.data = res.data 
    this.allData =    this.data.data.data
   this.access_token =  this.allData.access_token
 this.open_id =   this.allData.open_id
         this.router.navigate(['/all-tiktok-posts', 
         {accessToken : this.access_token ,
          open_id :this.open_id
        }
        ]);
         console.log(res.data, "res.data");
         
        
      }
    }, error => {

    })
  }


//     getToken(){
//     let req = {
//         client_key : this.clientID,
//         client_secret : this.clientsecretKey ,
//         grant_type : 'authorization_code' , 
//          code :  this.code
//     }
//     this.httpClient.post(`https://open-api.tiktok.com/oauth/access_token/`,req).subscribe(
//      (data: any) => {
//          console.log(data);
//      }
//    )
//  }


//  getToken(){
//   let urlencoded = new URLSearchParams();
//   urlencoded.append("client_key", this.clientID);
//   urlencoded.append("client_secret", this.clientsecretKey);
//   urlencoded.append("grant_type", 'authorization_code');
//   urlencoded.append("code", this.code);

//   let requestOptions = {
//     method: 'POST',
//     body: urlencoded,
//   };

//   fetch("https://open-api.tiktok.com/oauth/access_token", requestOptions)
//     .then(response => response.json())
//     .then((result) => {
//       console.log(result);
//     if(result.access_token){
//       }
//     })
    
// }


// gettoken(){
// var formdata = new FormData();
// formdata.append("client_key", "awn2rfp7tpbzf262");
// formdata.append("client_secret", "705fe2f93a7bf94c2d0480a83b4a0eca");
// formdata.append("grant_type", "authorization_code");
// formdata.append("code", this.code);

// let requestOptions = {
//   method: 'POST',
//   body: formdata
// };

// fetch("https://open-api.tiktok.com/oauth/access_token/", requestOptions)
//   .then(response => response.json())
//   .then(result => console.log(result))
//   .catch(error => console.log('error', error));
// }


// getAccessToken(){
//   let req = {
//  client_key : "awn2rfp7tpbzf262" ,
// client_secret :  "705fe2f93a7bf94c2d0480a83b4a0eca" , 
// grant_type :  "authorization_code" ,
// code : this.code

//   }
//   this.httpClient.post("https://open-api.tiktok.com/oauth/access_token/", req).subscribe((res:any) => {
//     console.log(res, "jkhwfjgwejfgewjfgwejfgej");
    
//   })
// }


  // getToken(){
  //   let req = {

  //   }
  //       this.httpClient.get("https://open-api.tiktok.com/oauth/access_token/?client_key=" +this.clientID+ '&client_secret='+ this.clientsecretKey + '&grant_type = authorization_code'+ '&code='+ this.code).subscribe(
  //    (data: any) => {
  //      if(data) {
  //       this.router.navigate(['/all-tiktok-posts']) 
  //      }
  //        console.log(data);
  // //              this.access_token =  result.data.access_token
  // // this.user_id =  result.data.open_id
  //    }
  //  )


  // //   fetch("https://open-api.tiktok.com/oauth/access_token/?client_key=" +this.clientID+ '&client_secret='+ this.clientsecretKey + '&grant_type = authorization_code'+ '&code='+ this.code)
  // //     .then(response => response.json())
  // //     .then((result) => {
  // //       console.log(result);
  // //     this.access_token =  result.data.access_token
  // // this.user_id =  result.data.open_id
       
  // //       if(result.data.access_token){
  // //       }
  // //     })
      
  // }

  // gettoken(){
  //   var formdata = new FormData();
  //   formdata.append("client_key", "awn2rfp7tpbzf262");
  //   formdata.append("client_secret", "705fe2f93a7bf94c2d0480a83b4a0eca");
  //   formdata.append("grant_type", "authorization_code");
  //   formdata.append("code", this.code);
    
  //   let requestOptions = {
  //     method: 'POST',
  //     //body: formdata,
  //   };
    
  //   fetch("https://open-api.tiktok.com/oauth/access_token/?client_key=awn2rfp7tpbzf262&&client_secret=705fe2f93a7bf94c2d0480a83b4a0eca&code="+this.code+"&grant_type=authorization_code", requestOptions)
  //     .then(response => response.json())
  //     .then(result => console.log(result))
  //     .catch(error => console.log('error', error));
  //   }


  // finalToken(){

  //   this.SERVER_ENDPOINT_REDIRECT = 'https://boome-wm.com/'

  //  const ApiUrl =  'https://open-api.tiktok.com/oauth/access_token/?client_key=awn2rfp7tpbzf262&client_secret=705fe2f93a7bf94c2d0480a83b4a0eca&grant_type=authorization_code&code=' + this.code 
  //  window.location.href = ApiUrl;
  //  if(window.location.href) {
  //   //  this.router.navigate(['/all-tiktok-posts']);

  //  }

  // // this.httpClient.get('https://open-api.tiktok.com/oauth/access_token/?client_key=awn2rfp7tpbzf262&client_secret=705fe2f93a7bf94c2d0480a83b4a0eca&grant_type=authorization_code&code=' + this.code).subscribe((res:any) => {
  // //   console.log(res, 'jhfghjfghewghewgfef');
    

  // // })
 
  // }

  // putMethod() {
  //   var formdata = new FormData();
  //   formdata.append("client_key", this.clientID);
  //   formdata.append("client_secret", this.clientsecretKey);
  //   formdata.append("grant_type", 'authorization_code');
  //   formdata.append("code", this.code);
  //   fetch( "https://open-api.tiktok.com/oauth/access_token/", {
  //     method: 'POST',
  //     headers: {
  //       'Content-Type': "application/json" ,
  //     },
  //     body: formdata,
  //   }).then(response => {
  //     console.log(response, "hfiehfghghrghdfhg");
      
  //   }).then(jsonResponse => {
  //   }).catch(error => {
  //   })
  // }

  // funtoken(){
  //   const csrfState = Math.random().toString(36).substring(2);
   
    
  //   app.get('/redirect', (req, res) => {
  //     const { code, state } = req.query;
  //     const { csrfState } = req.cookies;
  
  //     if (state !== csrfState) {
  //         res.status(422).send('Invalid state');
  //         return;
  //     }
  
  //     let url_access_token = 'https://open-api.tiktok.com/oauth/access_token/';
  //     url_access_token += '?client_key=' + this.clientID;
  //     url_access_token += '&client_secret=' + this.clientsecretKey;
  //     url_access_token += '&code=' + code;
  //     url_access_token += '&grant_type=authorization_code';
  
  //     fetch(url_access_token, {method: 'post'})
  //         .then(res => res.json())
  //         .then(json => {
  //             res.send(json);
  //         });
  // })
  // }





}
