import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-left-side-social',
  templateUrl: './left-side-social.component.html',
  styleUrls: ['./left-side-social.component.css']
})
export class LeftSideSocialComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
