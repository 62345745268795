import { Component, OnInit } from '@angular/core';
import { AbstractControl, ControlContainer, UntypedFormBuilder, FormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ApiListService } from 'src/app/services/Api/api-list.service';
import { HttpFunctionListService } from 'src/app/services/Http/http-function-list.service';
import { MessageHandlingService } from 'src/app/services/Common/message-handling.service';
import { Router } from '@angular/router';
import { Common_FunctionService } from '../../Pages/CommonInfluncer/common-function.service';
declare var $: any;
declare var connectVerificationForm: any;


@Component({
  selector: 'app-connect-social',
  templateUrl: './connect-social.component.html',
  styleUrls: ['./connect-social.component.css']
})
export class ConnectSocialComponent implements OnInit {
  SocialData:UntypedFormGroup
  youtubeActive = false;
  instagramActive = false;
  tiktokActive = false
 facebookActive = true
  profileData: any;
  data: any;

  constructor(public router:Router, public common: Common_FunctionService,   public messgae: MessageHandlingService, public apiList: ApiListService, public http: HttpFunctionListService, public formBuilder: UntypedFormBuilder) { }

  ngOnInit(): void {
    this.SocialData = this.formBuilder.group({
      facebookUsername: [''],
      youtubeUsername: [''],
      instaUsername: ['', [Validators.required]],
      tiktokUsername: [''],
    });
    this.common.subject.subscribe((res: any) => {
      this.profileData = res ;
      console.log(this.profileData,"this.profileData");
    });
    this.influencerInfo() ;
    new  connectVerificationForm
  }

  submit(){
    let data = {
      facebookUsername:this.SocialData.value.facebookUsername,
      youtubeUsername: this.SocialData.value.youtubeUsername,
      instaUsername: this.SocialData.value.instaUsername,
      tiktokUsername: this.SocialData.value.tiktokUsername,
    }
    this.http.post(this.apiList.influncerSaveSocialAccount, data).subscribe((res:any)=>{
      if (res.statusCode == 200) {
        this.messgae.success(res.message);
        this.router.navigate(['/campaign'])
      }
    },error=>{

    })
  }


  influencerInfo(){
    this.http.get(this.apiList.influncerInfo).subscribe((res:any) => {
      if(res.statusCode == 200){
      this.data = res.data;
      this.SocialData.patchValue({
       
        facebookUsername: res.data.user?.socialAccount.facebook.username,
        instaUsername : res.data.user?.socialAccount.instagram.username ,
        tiktokUsername: res.data.user?.socialAccount.tiktok.username ,
        youtubeUsername: res.data.user?.socialAccount.youtube.username ,
      })
  
  
      }
      
    },
    error => {

    }
    )

  } 
  

  next(value){
  
   //this.youtubeActive = true
    if(value =='youtube'){
      this.youtubeActive = true
      this.instagramActive = false
      this.tiktokActive = false
      this.facebookActive = false
    }else if(value =='instagram'){
      this.instagramActive = true
      this.youtubeActive = false;
      this.tiktokActive = false;
      this.facebookActive = false
    }else if(value =='tiktok'){
      this.youtubeActive = false;
      this.instagramActive = false
      this.tiktokActive = true;
      this.facebookActive = false
    }else{
      this.youtubeActive = false;
      this.instagramActive = false
      this.tiktokActive = false;
      this.facebookActive = false
    }
  }

  back(value:any){
    if(value =='youtube'){
      this.youtubeActive = true
      this.instagramActive = false
      this.tiktokActive = false
      this.facebookActive = false
    }else if(value =='instagram'){
      this.instagramActive = true
      this.youtubeActive = false;
      this.tiktokActive = false;
      this.facebookActive = false
    }else if(value =='tiktok'){
      this.youtubeActive = false;
      this.instagramActive = false
      this.tiktokActive = true;
      this.facebookActive = false
    }else if(value =='facebook'){
      this.youtubeActive = false;
      this.instagramActive = false
      this.tiktokActive = false;
      this.facebookActive = true
    }
    else{
      this.youtubeActive = false;
      this.instagramActive = false
      this.tiktokActive = false;
      this.facebookActive = false
    }

  }

  // skipSocialAcc(page){
  //   this.http.get(this.apiList.skipInfluencerScreen+'/' + page).subscribe((res:any)=>{
  //     console.log(res)
  //   })
  //  }

}
